import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { contactService } from '../services/contactService';
import { mockNoteService } from '../services/mockNoteService';
import { brokerageService } from '../services/brokerageService';
import { pipelineService } from '../services/pipelineService';
import { Brokerage } from '../components/brokerage/types';
import { Pipeline } from '../components/pipeline/types';
import { Contact, Note } from '../types';

// Move to constants file
const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function Dashboard() {
  const navigate = useNavigate();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [notes, setNotes] = useState<Note[]>([]);
  const [brokerages, setBrokerages] = useState<Brokerage[]>([]);
  const [pipelines, setPipelines] = useState<Pipeline[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const [contactsData, notesData, brokeragesData, pipelinesData] = await Promise.all([
        contactService.getAllContacts(),
        mockNoteService.getNotes(),
        brokerageService.getBrokerages(),
        pipelineService.getPipelines()
      ]);

      setContacts(contactsData as Contact[]);
      setNotes(notesData as Note[]);
      setBrokerages(brokeragesData);
      setPipelines(pipelinesData);
    };

    void fetchData();
  }, []);

  const stats = useMemo(() => [
    {
      title: 'Total Brokerages',
      value: brokerages.length.toLocaleString(),
      change: '+2 from last month',
      icon: (
        <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
        </svg>
      )
    },
    {
      title: 'Total Contacts',
      value: contacts.length.toLocaleString(),
      change: '+27 from last month',
      icon: (
        <svg className="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
      )
    },
    {
      title: 'Total Notes',
      value: notes.filter(n => n.type !== 'Task').length.toString(),
      change: `${notes.filter(n => new Date(n.createdAt) > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).length} this week`,
      icon: (
        <svg className="w-6 h-6 text-yellow-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
      )
    },
    {
      title: 'Total Pipeline',
      value: pipelines.length.toString(),
      change: `${pipelines.filter(p => p.Status.toLowerCase() === 'active').length} active`,
      icon: (
        <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
        </svg>
      )
    },
  ], [contacts.length, notes, brokerages.length, pipelines]);

  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState<number>(today.getMonth());
  const [currentYear, setCurrentYear] = useState<number>(today.getFullYear());

  const handlePreviousMonth = (): void => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(prev => prev - 1);
    } else {
      setCurrentMonth(prev => prev - 1);
    }
  };

  const handleNextMonth = (): void => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(prev => prev + 1);
    } else {
      setCurrentMonth(prev => prev + 1);
    }
  };

  const handleTodayClick = (): void => {
    const today = new Date();
    setCurrentMonth(today.getMonth());
    setCurrentYear(today.getFullYear());
  };

  const handleDayClick = (day: number | null): void => {
    if (!day) return;
    const newDate = new Date(currentYear, currentMonth, day);
    setSelectedDate(newDate);
  };

  const calendarData = useMemo(() => {
    const firstDay = new Date(currentYear, currentMonth, 1);
    const lastDay = new Date(currentYear, currentMonth + 1, 0);

    const daysInMonth = lastDay.getDate();
    const startingDay = firstDay.getDay();

    const calendar: (number | null)[][] = [];
    let week = new Array(7).fill(null);

    // Add empty days for the first week
    for (let i = 0; i < startingDay; i++) {
      week[i] = null;
    }

    // Fill in the days of the month
    let dayCounter = 1;
    for (let i = startingDay; i < 7; i++) {
      week[i] = dayCounter++;
    }
    calendar.push(week);

    // Fill in the rest of the weeks
    while (dayCounter <= daysInMonth) {
      week = new Array(7).fill(null);
      for (let i = 0; i < 7 && dayCounter <= daysInMonth; i++) {
        week[i] = dayCounter++;
      }
      calendar.push(week);
    }

    return calendar;
  }, [currentMonth, currentYear]);

  return (
    <div className="p-6 font-sans transition-colors duration-200 dark:bg-gray-900">
      <header className="flex items-center justify-between mb-8">
        <h1 className="text-gray-900 dark:text-white text-3xl font-bold tracking-tight">Dashboard</h1>
      </header>

      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8" aria-label="Statistics">
        {stats.map((stat) => (
          <div
            key={stat.title}
            className="p-6 bg-white dark:bg-gray-800 rounded-xl border dark:border-gray-700 transition-colors duration-200 cursor-pointer hover:shadow-lg"
            role="figure"
            onClick={() => {
              switch(stat.title) {
                case 'Total Brokerages':
                  navigate('/brokerages');
                  break;
                case 'Total Contacts':
                  navigate('/contacts');
                  break;
                case 'Total Notes':
                  navigate('/notes');
                  break;
                case 'Total Pipeline':
                  navigate('/pipelines');
                  break;
              }
            }}
          >
            <div className="flex justify-between items-start mb-4">
              <p className="text-sm text-gray-500 dark:text-gray-400">{stat.title}</p>
              <span>{stat.icon}</span>
            </div>
            <p className="text-2xl font-semibold mb-2 dark:text-white">{stat.value}</p>
            <p className="text-sm text-gray-500 dark:text-gray-400">{stat.change}</p>
          </div>
        ))}
      </section>

      <div className="grid grid-cols-1 lg:grid-cols-[2fr,1fr] gap-6">
        <section
          className="p-6 bg-white dark:bg-gray-800 rounded-xl border dark:border-gray-700 transition-colors duration-200"
          aria-label="Monthly calendar"
        >
          <div className="flex items-center justify-between mb-6">
            <h2 className="hidden md:block text-lg font-semibold dark:text-white">
              Calendar Overview
            </h2>
            <div className="flex items-center gap-4">
              <button
                onClick={handleTodayClick}
                className="inline-flex items-center px-4 py-1 border border-gray-300 dark:border-gray-600 rounded-md text-xs font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                Today
              </button>
              <button
                onClick={handlePreviousMonth}
                className="inline-flex items-center px-2 py-1 border border-gray-300 dark:border-gray-600 rounded-md text-xs font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                ←
              </button>
              <div className="flex items-center gap-2">
                <select
                  value={currentMonth}
                  onChange={(e) => setCurrentMonth(Number(e.target.value))}
                  className="block px-2 py-1 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-xs font-medium focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                >
                  {MONTHS.map((month, index) => (
                    <option key={month} value={index}>{month}</option>
                  ))}
                </select>
                <select
                  value={currentYear}
                  onChange={(e) => setCurrentYear(Number(e.target.value))}
                  className="block px-2 py-1 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-xs font-medium focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                >
                  {Array.from({ length: 10 }, (_, i) => currentYear - 5 + i).map(year => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
              </div>
              <button
                onClick={handleNextMonth}
                className="inline-flex items-center px-2 py-1 border border-gray-300 dark:border-gray-600 rounded-md text-xs font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                →
              </button>
            </div>
          </div>
          <div className="grid grid-cols-7 gap-1">
            {WEEKDAYS.map(day => (
              <div key={day} className="text-center py-2">
                <span className="text-xs font-medium text-gray-500 dark:text-gray-400">{day}</span>
              </div>
            ))}
            {calendarData.map((week, weekIndex) => (
              week.map((day, dayIndex) => {
                const isSelected = selectedDate && day === selectedDate.getDate() &&
                  currentMonth === selectedDate.getMonth() &&
                  currentYear === selectedDate.getFullYear();

                return (
                  <div
                    key={`${weekIndex}-${dayIndex}`}
                    onClick={() => handleDayClick(day)}
                    className={`
                      aspect-square p-2 relative cursor-pointer
                      ${day === null ? 'text-gray-300 dark:text-gray-700' : 'text-gray-900 dark:text-gray-100'}
                      ${day === new Date().getDate() && currentMonth === new Date().getMonth() && currentYear === new Date().getFullYear()
                        ? 'bg-blue-100 dark:bg-blue-900'
                        : ''}
                      ${isSelected ? 'ring-2 ring-blue-500' : ''}
                      hover:bg-gray-50 dark:hover:bg-gray-700
                    `}
                  >
                    {day && (
                      <span className="absolute top-1 left-2 text-sm font-medium">
                        {day}
                      </span>
                    )}
                  </div>
                );
              })
            ))}
          </div>
        </section>

        <section
          className="p-6 bg-white dark:bg-gray-800 rounded-xl border dark:border-gray-700 transition-colors duration-200"
          aria-label="Notes"
        >
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-semibold dark:text-white">Recent Notes</h2>
          </div>
          <div className="space-y-4">
            {notes.slice(0, 5).map((note) => (
              <div key={note.id} className="p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                <p className="text-sm font-medium dark:text-white mb-1">{note.title}</p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  {new Date(note.createdAt).toLocaleDateString()}
                </p>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default Dashboard;
