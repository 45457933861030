import { useTheme } from '../context/ThemeContext';
import { THEME_CLASSES } from '../constants/themeConstants';
import { useState, useEffect, useRef, MouseEvent as ReactMouseEvent } from 'react';
import { getUserData, logout } from '../services/authService';
import { useNavigate } from 'react-router-dom';
import NewNote from './NewNote';
import useDebounce from '../hooks/useDebounce';
import { contactService } from '../services/contactService';
import { Contact } from '../types';

interface NavbarProps {
  onToggleSidebar: () => void;
  currentContact?: Contact; // Add this prop
}

const Navbar: React.FC<NavbarProps> = ({ onToggleSidebar, currentContact }) => {
  const { isDark, toggleTheme } = useTheme();
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 300);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState<boolean>(false);
  const profileDropdownRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState<Contact[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const searchContainerRef = useRef<HTMLDivElement>(null);

  const toggleSidebar = (): void => {
    setSidebarOpen(!isSidebarOpen);
  };

  // Handle input change (simplified - only updates the input value)
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  // Use debounced value for search
  useEffect(() => {
    const performSearch = async () => {
      if (debouncedSearchValue.length >= 2) {
        try {
          const results = await contactService.searchContacts(debouncedSearchValue);
          setSearchResults(results);
          setShowDropdown(true);
        } catch (error) {
          console.error('Search error:', error);
          setSearchResults([]);
        }
      } else {
        setSearchResults([]);
        setShowDropdown(false);
      }
    };

    performSearch();
  }, [debouncedSearchValue]);

  const handleResultClick = (contact: Contact) => {
    setSearchResults([]);  // Clear search results
    setShowDropdown(false);  // Hide dropdown
    navigate(`/contacts/${contact.id}`);
  };

  // Close profile dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent): void => {
      const target = e.target as HTMLElement;
      if (!target.closest('.profile-container')) {
        setIsProfileDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(e.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <>
      <nav className="relative w-full z-50">
        <div className={`${THEME_CLASSES.background} relative`}>
          <div className="flex items-center justify-between px-4 py-2 md:px-6">
            {/* Left side: Logo, Search and Add Note */}
            <div className="flex items-center flex-1">
              {/* Logo */}
              <div onClick={onToggleSidebar} className="flex items-center mr-4 cursor-pointer">
                <svg className="text-blue-500 w-8 h-8 ml-1.5" viewBox="0 0 24 24" fill="currentColor">
                  <rect x="3" y="3" width="7" height="7" />
                  <rect x="14" y="3" width="7" height="7" />
                  <rect x="3" y="14" width="7" height="7" />
                  <rect x="14" y="14" width="7" height="7" />
                </svg>
              </div>
              {/* Search bar */}
              <div ref={searchContainerRef} className="relative w-[300px] search-container">
                <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                  <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>
                <input
                  type="text"
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder="Search with name.."
                  className={`block w-full pl-11 pr-4 py-2 border rounded-md ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder} focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium`}
                />
                {showDropdown && searchResults.length > 0 && (
                  <div className={`absolute z-50 w-full mt-1 rounded-md shadow-lg ${THEME_CLASSES.background} border ${THEME_CLASSES.border}`}>
                    <ul className="py-1">
                      {searchResults.map((contact) => (
                        <li
                          key={contact.id}
                          onClick={() => handleResultClick(contact)}
                          className={`px-4 py-2 cursor-pointer ${THEME_CLASSES.hover.background} ${THEME_CLASSES.text.primary}`}
                        >
                          {`${contact.firstName} ${contact.lastName}`}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              {/* Desktop-only Add Note button */}
              <div className="hidden md:flex items-center ml-4">
                <button
                  onClick={toggleSidebar}
                  className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium ${THEME_CLASSES.button.primary}`}
                >
                  <svg className="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                  </svg>
                  Add Note
                </button>
              </div>
            </div>

            {/* Right side: Profile section */}
            <div className="flex items-center gap-2">
              {/* Profile container */}
              <div className="relative profile-container flex items-center" ref={profileDropdownRef}>
                <div
                  className="flex items-center cursor-pointer py-2"
                  onClick={(e: ReactMouseEvent) => {
                    e.stopPropagation();
                    setIsProfileDropdownOpen(!isProfileDropdownOpen);
                  }}
                >
                  <img
                    src={getUserData()?.picture}
                    alt={getUserData()?.name}
                    className="w-10 h-10 rounded-full object-cover"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.src = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiPjxwYXRoIHN0cm9rZUxpbmVjYXA9InJvdW5kIiBzdHJva2VMaW5lam9pbj0icm91bmQiIHN0cm9rZVdpZHRoPSIyIiBkPSJNMTYgN2E0IDQgMCAxMS04IDAgNCA0IDAgMDE4IDB6TTEyIDE0YTcgNyAwIDAwLTcgN2gxNGE3IDcgMCAwMC03LTd6Ii8+PC9zdmc+';
                    }}
                  />
                  <span className="hidden md:block text-sm text-gray-700 dark:text-gray-200 ml-2">
                    {getUserData()?.name}
                  </span>
                </div>

                {/* Dropdown Menu */}
                {isProfileDropdownOpen && (
                  <div className={`absolute right-0 top-full mt-3 w-48 ${THEME_CLASSES.background} rounded-md shadow-lg py-1 border ${THEME_CLASSES.border} z-[60]`}>
                    <button
                      onClick={() => {
                        logout();
                      }}
                      className={`w-full px-4 py-2 text-sm text-right ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
                    >
                      Log Out
                    </button>
                  </div>
                )}
              </div>

              {/* Theme toggle */}
              <button
                onClick={toggleTheme}
                className={`hidden md:flex p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
              >
                {isDark ? (
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                ) : (
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* NewNote component */}
        <NewNote
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          initialContact={currentContact}
        />
      </nav>
    </>
  );
};

export default Navbar;
