import { Contact, SearchResult } from '../types';
import { getAuthHeaders, handleResponse } from './authService';

const API_BASE_URL = '/api';
//const API_BASE_URL = 'http://localhost:7071/api';
const DEFAULT_PAGE_SIZE = 50;

export const contactService = {
  /**
   * Get all contacts without pagination.
   * Use this when you need the complete list of contacts, e.g. for stats or lookups.
   * For displaying paginated lists, use getAllContactsPaginated instead.
   */
  async getAllContacts(): Promise<Contact[]> {
    // Get all contacts by using maximum allowed page size
    const response = await fetch(`${API_BASE_URL}/contacts?pageSize=100`, {
      headers: await getAuthHeaders()
    });
    return handleResponse(response);
  },

  /**
   * Get contacts with pagination.
   * Use this for displaying paginated lists of contacts.
   */
  async getAllContactsPaginated(page: number = 1, pageSize: number = DEFAULT_PAGE_SIZE, searchString?: string): Promise<Contact[]> {
    try {
      const params = new URLSearchParams({
        page: page.toString(),
        pageSize: pageSize.toString(),
        ...(searchString ? { search: searchString } : {})
      });

      const response = await fetch(`${API_BASE_URL}/contacts?${params}`, { headers: await getAuthHeaders() });
      return handleResponse(response);
    } catch (error) {
      console.error('Error fetching contacts:', error);
      throw error;
    }
  },

  async getContactById(id: number): Promise<Contact> {
    const response = await fetch(`${API_BASE_URL}/contacts?id=${id}`, {
      headers: await getAuthHeaders()
    });
    return handleResponse(response);
  },

  async searchContacts(query: string): Promise<Contact[]> {
    if (!query || query.length < 2) return [];

    const response = await fetch(
      `${API_BASE_URL}/contacts?search=${encodeURIComponent(query)}`,
      {
        headers: await getAuthHeaders()
      }
    );
    return response.json();
  },

  async getBrokerages(): Promise<{ id: number; name: string; province: string; }[]> {
    const response = await fetch(`${API_BASE_URL}/contacts?brokerages=true`, {
      headers: await getAuthHeaders()
    });
    return handleResponse(response);
    return response.json();
  },

  async createNote(data: any): Promise<any> {
    const response = await fetch(`${API_BASE_URL}/contacts/notes`, {
      method: 'POST',
      headers: {
        ...(await getAuthHeaders()),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        procedure: 'CreateNote',
        parameters: {
          Title: data.title,
          Content: data.content,
          CreatorID: data.creatorID,
          EmailList: data.emailList,
          Type: data.type,
          Visibility: data.visibility,
          Tags: data.tags
        }
      })
    });

    if (!response.ok) {
      throw new Error('Failed to create note');
    }

  }
};
