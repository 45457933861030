import { FC, useEffect, useState, useMemo } from 'react';
import { THEME_CLASSES } from '../constants/themeConstants';
import { brokerageService } from '../services/brokerageService';
import { Brokerage } from '../components/brokerage/types';
import BrokerageTable from '../components/brokerage/BrokerageTable';

const Brokerages: FC = () => {
  const [brokerages, setBrokerages] = useState<Brokerage[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState(() => {
    // Initialize from localStorage
    return localStorage.getItem('brokerages-search') || '';
  });

  const filteredBrokerages = useMemo(() => {
    const trimmedQuery = searchQuery.trim();
    if (!brokerages) return [];
    if (!trimmedQuery) return brokerages;

    const keywords = trimmedQuery
      .toLowerCase()
      .split(' ')
      .map(k => k.trim())
      .filter(k => k.length > 0);

    return brokerages.filter(brokerage => {
      if (!brokerage) return false;

      const searchableFields = [
        brokerage.name || '',
        brokerage.franchiseGroupName || '',
        brokerage.city || '',
        brokerage.province || ''
      ].map(field => field.toLowerCase());

      return keywords.every(keyword =>
        searchableFields.some(field => field.includes(keyword))
      );
    });
  }, [brokerages, searchQuery]);

  // Save search to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('brokerages-search', searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    const fetchBrokerages = async () => {
      try {
        const data = await brokerageService.getBrokerages();
        setBrokerages(data || []);
        setError(null);
      } catch (err) {
        setError('Failed to load brokerages');
        console.error('Error fetching brokerages:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchBrokerages();
  }, []);

  if (loading) {
    return (
      <div className={`p-6 ${THEME_CLASSES.text.primary}`}>
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`p-6 ${THEME_CLASSES.text.primary}`}>
        <div className="flex items-center justify-center h-64">
          <div className="text-red-500">{error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex items-center mb-6 gap-4">
        <h1 className={`text-2xl font-semibold ${THEME_CLASSES.text.primary}`}>Brokerages</h1>
        <div className="w-96 relative ml-auto">
          <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
            <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
            </svg>
          </div>
          <input
            type="text"
            placeholder="Search brokerages..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={`w-full pl-11 pr-8 py-2 border rounded-md text-xs font-medium focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder}`}
          />
          {searchQuery && (
            <button
              onClick={() => setSearchQuery('')}
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
            >
              <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary} hover:${THEME_CLASSES.text.primary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          )}
        </div>
      </div>
      <div className={`w-full rounded-lg border overflow-hidden ${THEME_CLASSES.background} ${THEME_CLASSES.border}`}>
        <BrokerageTable 
          brokerages={filteredBrokerages || []}
          keywords={searchQuery
            .toLowerCase()
            .split(' ')
            .map(k => k.trim())
            .filter(k => k.length > 0)}
        />
      </div>
    </div>
  );
};

export default Brokerages;
