// Theme colors
export const THEME_COLORS = {
  light: {
    background: '#F9FAFB', // bg-gray-50
  },
  dark: {
    background: '#111827', // bg-gray-900
  }
} as const;

// Tailwind classes
export const THEME_CLASSES = {
  background: 'bg-gray-50 dark:bg-gray-900',
  border: 'border-gray-200 dark:border-gray-800',
  text: {
    primary: 'text-gray-900 dark:text-white',
    secondary: 'text-gray-600 dark:text-gray-300',
  },
  hover: {
    background: 'hover:bg-gray-100 dark:hover:bg-gray-700',
    text: 'hover:text-gray-900 dark:hover:text-white',
  },
  input: {
    background: 'bg-white dark:bg-gray-700',
    border: 'border-gray-300 dark:border-gray-600',
    text: 'text-gray-900 dark:text-gray-100',
    placeholder: 'placeholder-gray-500 dark:placeholder-gray-400'
  },
  button: {
    primary: 'bg-blue-600 hover:bg-blue-700 text-white',
    secondary: 'bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-200'
  }
} as const;

// Breakpoints
export const BREAKPOINTS = {
  mobile: '(max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 1023px)',
  desktop: '(min-width: 1024px)',
} as const;

// Sidebar
export const SIDEBAR = {
  collapsedWidth: 'w-16',
  expandedWidth: 'w-52',
} as const;
