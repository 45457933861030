import companiesData from '../data/companies.json';
import { Contact, SearchResult } from '../types';

interface Company {
  'Company ID': number;
  'Company Name': string;
  Province: string;
}

interface Brokerage {
  id: number;
  name: string;
  province: string;
}

interface MockContactService {
  getContactById: (id: number) => Promise<Contact | undefined>;
  getContacts: () => Promise<Contact[]>;
  searchContacts: (query: string) => Promise<SearchResult[]>;
  getBrokerages: () => Promise<Brokerage[]>;
}

const firstNames = ['John', 'Sarah', 'Michael', 'Emma', 'David', 'Lisa', 'James', 'Jennifer', 'Robert', 'Emily'];
const lastNames = ['Smith', 'Johnson', 'Brown', 'Wilson', 'Taylor', 'Davies', 'Evans', 'Thomas', 'Roberts', 'Walker'];
const employeeStatus: Contact['employeeStatus'][] = ['Active', 'Inactive'];
const departments: Contact['department'][] = ['Sales', 'Support'];
const employmentTypes = ['Agent', 'Broker', 'Administrator', 'Manager', 'Support Staff'];
const teamNames = ['Sales Team A', 'Sales Team B', 'Support Team', 'Marketing Team', 'Operations Team'];
const employeeTypes: Contact['employeeType'][] = ['Finance', 'Agent', 'Licensed Assistant', 'Unlicensed Asst.', 'Broker Manager', 'Broker Owner'];

const salutations = ['Mr.', 'Mrs.', 'Ms.', 'Dr.'];
const languages: Contact['preferredLanguage'][] = ['English', 'French'];
const sexOptions: Contact['sex'][] = ['Male', 'Female', 'Other', 'Prefer not to say'];
const titles = ['Sales Representative', 'Broker', 'Team Lead', 'Regional Manager'];
const designations = ['ABR', 'CRS', 'GRI', 'SRES', 'e-PRO'];
const recruitmentSources = ['Referral', 'Job Board', 'Direct Application', 'LinkedIn', 'Career Fair'];
const previousAffiliations = ['Century 21', 'RE/MAX', 'Keller Williams', 'Independent'];
const futureAffiliations = ['Expansion', 'Management', 'Retirement', 'Unknown'];

const generateRandomDate = (start: Date, end: Date): string => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).toISOString().split('T')[0];
};

const generateMockContact = (id: number): Contact => {
  const companies = companiesData.Export.map(company => company['Company Name']);
  const company = companiesData.Export[Math.floor(Math.random() * companiesData.Export.length)] as Company;
  const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
  const joinDate = generateRandomDate(new Date(2020, 0, 1), new Date(2023, 11, 31));

  // Generate employment history
  const employmentHistory = Array.from({ length: Math.floor(Math.random() * 3) }, () => ({
    type: employmentTypes[Math.floor(Math.random() * employmentTypes.length)],
    date: generateRandomDate(new Date(joinDate), new Date())
  }));

  // Generate team history
  const teamHistory = Array.from({ length: Math.floor(Math.random() * 2) + 1 }, () => {
    const teamJoinDate = generateRandomDate(new Date(joinDate), new Date());
    const hasLeft = Math.random() > 0.7;
    return {
      teamName: teamNames[Math.floor(Math.random() * teamNames.length)],
      joinDate: teamJoinDate,
      leaveDate: hasLeft ? generateRandomDate(new Date(teamJoinDate), new Date()) : undefined
    };
  });

  return {
    id,
    RlpPersonId: `RLP${Math.floor(10000 + Math.random() * 90000)}`,
    salutation: salutations[Math.floor(Math.random() * salutations.length)],
    firstName,
    lastName,
    displayName: `${firstName} ${lastName}`,
    preferredLanguage: languages[Math.floor(Math.random() * languages.length)],
    sex: sexOptions[Math.floor(Math.random() * sexOptions.length)],
    title: titles[Math.floor(Math.random() * titles.length)],
    email: `${firstName.toLowerCase()}.${lastName.toLowerCase()}@royallepage.com`,
    communicationsEmail: `${firstName.toLowerCase()}.${lastName.toLowerCase()}.comm@royallepage.com`,
    companyId: company['Company ID'],
    companyName: company['Company Name'],
    province: company.Province,
    company: companies[Math.floor(Math.random() * companies.length)],
    employeeStatus: employeeStatus[Math.floor(Math.random() * employeeStatus.length)],
    phone: `(${Math.floor(Math.random() * 900) + 100}) ${Math.floor(Math.random() * 900) + 100}-${Math.floor(Math.random() * 9000) + 1000}`,
    status: Math.random() > 0.1 ? 'Active' : 'Inactive',
    department: departments[Math.floor(Math.random() * departments.length)],
    employeeType: employeeTypes[Math.floor(Math.random() * employeeTypes.length)],
    startDate: generateRandomDate(new Date(2015, 0, 1), new Date(2020, 11, 31)),
    protectionRoyale: Math.random() > 0.5,
    affiliatedBroker: Math.random() > 0.7 ? `${firstNames[Math.floor(Math.random() * firstNames.length)]} ${lastNames[Math.floor(Math.random() * lastNames.length)]}` : '',
    commercial: Math.random() > 0.8,
    designation: designations[Math.floor(Math.random() * designations.length)],
    recruitmentSource: recruitmentSources[Math.floor(Math.random() * recruitmentSources.length)],
    previousAffiliation: previousAffiliations[Math.floor(Math.random() * previousAffiliations.length)],
    futureAffiliation: futureAffiliations[Math.floor(Math.random() * futureAffiliations.length)],
    address: Math.random() > 0.3 ? `${Math.floor(Math.random() * 1000) + 100} ${lastNames[Math.floor(Math.random() * lastNames.length)]} St` : undefined,
    lastContact: Math.random() > 0.5 ? generateRandomDate(new Date(2023, 0, 1), new Date()) : undefined,
    notes: Math.random() > 0.7 ? 'Sample contact notes' : undefined,
    joinDate,
    employmentHistory,
    teamHistory
  };
};

// Generate 100 mock contacts
const mockContacts: Contact[] = Array.from({ length: 100 }, (_, i) => generateMockContact(i + 1));

export const mockContactService: MockContactService = {
  getContactById: (id: number): Promise<Contact | undefined> =>
    Promise.resolve(mockContacts.find(contact => contact.id === id)),

  getContacts: (): Promise<Contact[]> =>
    Promise.resolve(mockContacts),

  searchContacts: (query: string): Promise<SearchResult[]> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const normalizedQuery = query.toLowerCase();
        const results = mockContacts
          .filter(contact =>
            contact.displayName.toLowerCase().includes(normalizedQuery) ||
            contact.company.toLowerCase().includes(normalizedQuery)
          )
          .map(contact => ({
            id: contact.id.toString(),
            title: contact.displayName,
            type: 'contact' as const,
            url: `/contacts/${contact.id}`
          }));
        resolve(results);
      }, 150);
    });
  },

  getBrokerages: (): Promise<Brokerage[]> => {
    return Promise.resolve(
      companiesData.Export.map(company => ({
        id: company['Company ID'],
        name: company['Company Name'],
        province: company.Province
      }))
    );
  },
};

// Export mockContacts for use in other services
export { mockContacts };
