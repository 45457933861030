import { FC } from 'react';
import { Contact } from '../../types';

interface ContactInfoProps {
  contact: Contact;
  onGmailImport: () => void;
}


  const getStatusInfo = (status: Contact['employeeStatus']) => {
    switch (status) {
      case 'Active':
        return {
          color: 'text-green-600 dark:text-green-500',
          icon: (
            <svg className="w-4 h-4 text-green-600 dark:text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          )
        };
      case 'Inactive':
        return {
          color: 'text-red-600 dark:text-red-500',
          icon: (
            <svg className="w-4 h-4 text-red-600 dark:text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          )
        };
      default:
        return {
          color: 'text-gray-600 dark:text-gray-400',
          icon: (
            <svg className="w-4 h-4 text-gray-600 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          )
        };
    }
  };

const ContactInfo: FC<ContactInfoProps> = ({ contact, onGmailImport }) => {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700">
      <div className="flex justify-between items-start mb-4">
        <span>
          <span className="text-xl font-bold text-gray-900 dark:text-white">{contact.displayName}</span>
          &nbsp;
          <span className={`inline-flex items-center gap-1.5 ${getStatusInfo(contact.employeeStatus).color}`}>
            {getStatusInfo(contact.employeeStatus).icon}
            {contact.employeeStatus}
          </span>
        </span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <p className="text-gray-600 dark:text-gray-300 text-xs">
            <span className="text-xs font-medium text-gray-900 dark:text-white">Company:</span> {contact.companyName}
          </p>
          <p className="text-gray-600 dark:text-gray-300 text-xs">
            <span className="text-xs font-medium text-gray-900 dark:text-white">Province:</span> {contact.province}
          </p>
        </div>
        <div>
          <p className="text-gray-600 dark:text-gray-300 text-xs">
            <span className="text-xs font-medium text-gray-900 dark:text-white">Email:</span> {contact.email}
          </p>
          <p className="text-gray-600 dark:text-gray-300 text-xs">
            <span className="text-xs font-medium text-gray-900 dark:text-white">Phone:</span> {contact.phone}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
