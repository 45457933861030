import { Pipeline } from '../components/pipeline/types';
import { getAuthHeaders } from './authService';

class PipelineService {
  async getPipelines(): Promise<Pipeline[]> {
    const headers = await getAuthHeaders();
    const response = await fetch('/api/pipelines', { headers });
    if (!response.ok) {
      throw new Error('Failed to fetch pipelines');
    }
    return response.json();
  }

  async getPipeline(id: number): Promise<Pipeline> {
    const headers = await getAuthHeaders();
    const response = await fetch(`/api/pipelines/${id}`, { headers });
    if (!response.ok) {
      throw new Error('Failed to fetch pipeline');
    }
    return response.json();
  }

  async createPipeline(pipeline: Omit<Pipeline, 'ID'>): Promise<Pipeline> {
    const headers = await getAuthHeaders();
    const response = await fetch('/api/pipelines', {
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(pipeline),
    });
    if (!response.ok) {
      throw new Error('Failed to create pipeline');
    }
    return response.json();
  }

  async updatePipeline(id: number, pipeline: Partial<Pipeline>): Promise<Pipeline> {
    const headers = await getAuthHeaders();
    const response = await fetch(`/api/pipelines/${id}`, {
      method: 'PATCH',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(pipeline),
    });
    if (!response.ok) {
      throw new Error('Failed to update pipeline');
    }
    return response.json();
  }

  async deletePipeline(id: number): Promise<void> {
    const headers = await getAuthHeaders();
    const response = await fetch(`/api/pipelines/${id}`, {
      method: 'DELETE',
      headers,
    });
    if (!response.ok) {
      throw new Error('Failed to delete pipeline');
    }
  }
}

export const pipelineService = new PipelineService();
