import { FC } from 'react';
import { Contact, TimelineEvent } from '../../types';

interface TimelineEventsProps {
  contact: Contact;
}

const TimelineEvents: FC<TimelineEventsProps> = ({ contact }) => {
  const getTimelineEvents = (contact: Contact): TimelineEvent[] => {
    const events: TimelineEvent[] = [
      {
        type: 'joined',
        date: contact.joinDate,
        details: 'Joined the organization'
      }
    ];

    // Add employment changes
    contact.employmentHistory?.forEach(history => {
      events.push({
        type: 'employment_change',
        date: history.date,
        details: `Changed to ${history.type}`
      });
    });

    // Add team changes
    contact.teamHistory?.forEach(history => {
      events.push({
        type: 'team_joined',
        date: history.joinDate,
        details: `Joined ${history.teamName}`
      });

      if (history.leaveDate) {
        events.push({
          type: 'team_left',
          date: history.leaveDate,
          details: `Left ${history.teamName}`
        });
      }
    });

    // Sort events by date
    return events.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  };

  const getEventIcon = (type: TimelineEvent['type']) => {
    switch (type) {
      case 'joined': return '👋';
      case 'employment_change': return '💼';
      case 'team_joined': return '➡️';
      case 'team_left': return '⬅️';
      default: return '•';
    }
  };

  const events = getTimelineEvents(contact);

  return (
    <div id="timeline-container" className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700">
      <h2 id="timeline-heading" className="text-xl font-bold text-gray-900 dark:text-white mb-4">Timeline</h2>

      <div id="timeline-scroll-container" className="overflow-x-auto">
        <div id="timeline-relative-container" className="relative min-w-full py-[7rem] pl-[8rem]"> {/* Added pl-[8rem] */}
          {/* Timeline line */}
          <div id="timeline-line" className="absolute h-0.5 w-full bg-gray-300 dark:bg-gray-600 top-1/2 transform -translate-y-1/2"></div>

          {/* Events container */}
          <div id="timeline-events-container" className="relative flex justify-start min-w-max pr-4"> {/* Changed justify-between to justify-start */}
            {events.map((event, index) => (
              <div id={`timeline-event-wrapper-${index}`} key={`${event.type}-${index}`} className="relative mx-16 first:ml-0 last:mr-0"> {/* Changed mx-16 back to mx-8 */}

                {/* Centered dot */}
                <div id={`timeline-event-dot-${index}`} className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
                  <div id={`timeline-event-dot-inner-${index}`} className="w-4 h-4 rounded-full bg-blue-600 dark:bg-blue-500 shadow-md"></div>
                </div>

                {/* Connecting line */}
                <div id={`timeline-event-line-${index}`} className={`absolute left-1/2 w-1 bg-gray-300 dark:bg-gray-600 transform -translate-x-1/2 ${
                  index % 2 === 0
                    ? 'h-[1.5rem] -top-[1.5rem]'
                    : 'h-[1.5rem] top-1/2'
                }`}></div>

                {/* Event box */}
                <div id={`timeline-event-box-${index}`} className={`absolute left-1/2 transform -translate-x-1/2 ${
                  index % 2 === 0 ? '-translate-y-full -top-[1.5rem]' : 'top-[1.5rem]'
                }`}>
                  <div id={`timeline-event-content-${index}`} className="flex flex-col p-3 rounded-lg shadow-md bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 w-48 hover:shadow-xl hover:-translate-y-0.5 transition-all duration-200">
                    <div id={`timeline-event-header-${index}`} className="flex items-center gap-2 mb-1">
                      <span id={`timeline-event-icon-${index}`} className="text-lg">{getEventIcon(event.type)}</span>
                      <p id={`timeline-event-details-${index}`} className="text-xs font-medium text-gray-900 dark:text-white truncate">
                        {event.details}
                      </p>
                    </div>
                    <time id={`timeline-event-date-${index}`} className="text-xs text-gray-600 dark:text-gray-300">
                      {new Date(event.date).toLocaleDateString()}
                    </time>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimelineEvents;
