import { FC, useState, FormEvent, ChangeEvent } from 'react';
import { NOTE_TYPES, NOTE_VISIBILITY_OPTIONS } from '../../constants/noteConstants';
import { Note } from '../../types';

interface EditableNoteProps {
  note: Note;
  onSave: (updatedNote: Note) => void;
  onCancel: () => void;
}

const EditableNote: FC<EditableNoteProps> = ({ note, onSave, onCancel }) => {
  const [editedNote, setEditedNote] = useState<Note>({ ...note });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSave(editedNote);
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    field: keyof Note
  ) => {
    setEditedNote(prev => ({ ...prev, [field]: e.target.value }));
  };

  const handleTagsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEditedNote(prev => ({
      ...prev,
      tags: e.target.value.split(',').map(tag => tag.trim()).filter(Boolean)
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="space-y-2">
        <input
          type="text"
          value={editedNote.title}
          onChange={(e) => handleInputChange(e, 'title')}
          className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
          placeholder="Note title"
        />
        <div className="flex gap-4">
          <select
            value={editedNote.type}
            onChange={(e) => handleInputChange(e, 'type')}
            className="flex-1 block px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
          >
            {NOTE_TYPES.filter(type => type !== 'All').map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>

          <select
            value={editedNote.visibility || 'me'}
            onChange={(e) => handleInputChange(e, 'visibility')}
            className="flex-1 block px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
          >
            {NOTE_VISIBILITY_OPTIONS.map(option => (
              <option key={option.value} value={option.value}>
                {option.icon} {option.label}
              </option>
            ))}
          </select>
        </div>
        <textarea
          value={editedNote.content}
          onChange={(e) => handleInputChange(e, 'content')}
          className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
          rows={3}
          placeholder="Note content"
        />
        <input
          type="text"
          value={editedNote.tags.join(', ')}
          onChange={handleTagsChange}
          className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
          placeholder="Tags (comma-separated)"
        />
      </div>
      <div className="flex gap-2">
        <button
          type="submit"
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-blue-600 hover:bg-blue-700"
        >
          Save
        </button>
        <button
          type="button"
          onClick={onCancel}
          className="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-xs font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default EditableNote;
