import { GmailMessage } from '../types';

const mockMessages: GmailMessage[] = [
  {
    id: 1,
    subject: 'Meeting Follow-up',
    from: 'john.doe@example.com',
    to: 'contact@company.com',
    date: '2024-01-15T10:30:00Z',
    snippet: 'Thank you for the productive meeting today...',
    isFromContact: true,
    selected: false,
    visibility: 'me'
  },
  {
    id: 2,
    subject: 'Contract Review',
    from: 'legal@partner.com',
    to: 'contact@company.com',
    cc: 'manager@company.com',
    date: '2024-01-14T15:45:00Z',
    snippet: 'Please find attached the revised contract...',
    isFromContact: false,
    selected: false,
    visibility: 'team'
  },
  {
    id: 3,
    subject: 'Project Update',
    from: 'project.lead@example.com',
    to: 'team@company.com',
    date: '2024-01-13T09:15:00Z',
    snippet: 'Here is the latest status update for...',
    isFromContact: true,
    selected: false,
    visibility: 'everyone'
  }
];

interface ImportResult {
  success: boolean;
  messages: GmailMessage[];
}

interface MockGmailService {
  getMessages: (searchTerm?: string) => Promise<GmailMessage[]>;
  getMessage: (id: number) => Promise<GmailMessage | undefined>;
  importMessages: (messageIds: number[]) => Promise<ImportResult>;
}

export const mockGmailService: MockGmailService = {
  getMessages: (searchTerm?: string) => {
    return new Promise<GmailMessage[]>((resolve) => {
      let filteredMessages = [...mockMessages];
      if (searchTerm) {
        const term = searchTerm.toLowerCase();
        filteredMessages = mockMessages.filter(msg => 
          msg.subject.toLowerCase().includes(term) ||
          msg.from.toLowerCase().includes(term) ||
          msg.to.toLowerCase().includes(term) ||
          msg.snippet.toLowerCase().includes(term)
        );
      }
      setTimeout(() => resolve(filteredMessages), 100);
    });
  },

  getMessage: (id: number) => {
    return new Promise<GmailMessage | undefined>((resolve) => {
      const message = mockMessages.find(msg => msg.id === id);
      setTimeout(() => resolve(message ? { ...message } : undefined), 100);
    });
  },

  importMessages: (messageIds: number[]) => {
    return new Promise<ImportResult>((resolve) => {
      const importedMessages = mockMessages
        .filter(msg => messageIds.includes(msg.id))
        .map(msg => ({ ...msg }));
      
      setTimeout(() => resolve({
        success: true,
        messages: importedMessages
      }), 100);
    });
  }
};
