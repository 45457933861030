import { Brokerage } from '../types';
import { getAuthHeaders } from './authService';

const API_BASE_URL = '/api';
//const API_BASE_URL = 'http://localhost:7071/api';

class CompanyService {
  private apiUrl = `${API_BASE_URL}/contacts?brokerages`;

  async getAllCompanies(): Promise<Brokerage[]> {
    try {
      const response = await fetch(this.apiUrl, { headers: await getAuthHeaders() });
      if (!response.ok) {
        throw new Error('Failed to fetch companies');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching companies:', error);
      return [];
    }
  }

}

export const companyService = new CompanyService();
