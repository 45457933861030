interface UserData {
  email: string;
  name: string;
  picture: string;
  accessToken: string;
  refreshToken?: string;
  expiresAt?: number; // Timestamp when token expires
  locale: string;
  emailVerified: boolean;
}

let userData: UserData | null = null;

function isTokenExpired(): boolean {
  if (!userData?.expiresAt) return true;
  // Check if token expires in the next 5 minutes
  return Date.now() + 5 * 60 * 1000 >= userData.expiresAt;
}

function initializeAuth(): void {
  // Check for existing auth data in localStorage
  const storedData = localStorage.getItem('userData');
  if (storedData) {
    try {
      userData = JSON.parse(storedData);
    } catch (error) {
      console.error('Error parsing user data:', error);
    }
  }
}

function isAuthenticated(): boolean {
  initializeAuth(); // Always check for latest cookie data
  return userData !== null;
}

function getUserData(): UserData | null {
  initializeAuth(); // Always check for latest cookie data
  return userData;
}

async function login(): Promise<void> {
  window.location.href = '/api/auth';
}

// Handle auth callback with URL fragment
function handleAuthCallback(): void {
  const hash = window.location.hash;
  if (hash && hash.includes('userData=')) {
    const encodedData = hash.split('userData=')[1];
    try {
      const decodedData = decodeURIComponent(encodedData);
      userData = JSON.parse(decodedData);
      localStorage.setItem('userData', decodedData);
      // Clear the hash
      window.history.replaceState(null, '', window.location.pathname + window.location.search);
    } catch (error) {
      console.error('Error parsing auth callback data:', error);
    }
  }
}

// Handle service worker messages for token updates
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', (event) => {
    if (event.data?.type === 'AUTH_REFRESH' && event.data?.payload) {
      const { accessToken, expiresIn } = event.data.payload;
      if (userData && accessToken) {
        userData.accessToken = accessToken;
        userData.expiresAt = Date.now() + (expiresIn * 1000);
        localStorage.setItem('userData', JSON.stringify(userData));
      }
    }
  });
}

// Initialize auth state when module is loaded
initializeAuth();
// Check for auth callback data
handleAuthCallback();

function logout(): void {
  // Clear localStorage data
  localStorage.removeItem('userData');
  localStorage.removeItem('codeVerifier');
  userData = null;
  window.location.href = '/';
}

async function refreshAccessToken(): Promise<void> {
  if (!userData?.refreshToken) {
    throw new Error('No refresh token available');
  }

  try {
    const response = await fetch('/api/auth/refresh', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refreshToken: userData.refreshToken }),
    });

    if (!response.ok) {
      throw new Error('Token refresh failed');
    }

    const { accessToken, expiresIn } = await response.json();
    
    if (userData) {
      userData.accessToken = accessToken;
      // Set token expiration time (expiresIn is in seconds)
      userData.expiresAt = Date.now() + (expiresIn * 1000);
      // Update localStorage with new token
      localStorage.setItem('userData', JSON.stringify(userData));
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    logout();
    throw error;
  }
}

async function getAuthHeaders(): Promise<HeadersInit> {
  if (!userData) {
    window.location.href = '/api/auth';
    throw new Error('Unauthorized');
  }

  try {
    // Only refresh if token is expired or about to expire
    if (userData.refreshToken && isTokenExpired()) {
      await refreshAccessToken();
    }

    return {
      'X-Google-Auth': `Bearer ${userData.accessToken}`
    };
  } catch (error) {
    console.error('Error getting auth headers:', error);
    throw error;
  }
}

// Initialize auth state when module is loaded
initializeAuth();

async function handleResponse(response: Response) {
  if (response.status === 401) {
    window.location.href = '/api/auth';
    throw new Error('Unauthorized');
  }
  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }
  return response.json();
}

export type { UserData };
export {
  isAuthenticated,
  getUserData,
  login,
  logout,
  getAuthHeaders,
  handleResponse
};
