import React from 'react';
import { Link } from 'react-router-dom';
import { Contact } from './types';

interface ContactTableProps {
  contacts: Contact[];
  loading: boolean;
  hasMore: boolean;
  lastElementCallback: (node: HTMLElement | null) => void;
  onRowClick: (id: number) => void;
  onSort: (column: keyof Contact, direction: 'asc' | 'desc') => void;
  sortColumn?: keyof Contact;
  sortDirection?: 'asc' | 'desc';
}

const ContactTable: React.FC<ContactTableProps> = ({
  contacts,
  loading,
  hasMore,
  lastElementCallback,
  onRowClick,
  onSort,
  sortColumn,
  sortDirection
}) => {
  const getStatusInfo = (status: Contact['employeeStatus']) => {
    switch (status) {
      case 'Active':
        return {
          color: 'text-green-600 dark:text-green-500',
          icon: (
            <svg className="w-4 h-4 text-green-600 dark:text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          )
        };
      case 'Inactive':
        return {
          color: 'text-red-600 dark:text-red-500',
          icon: (
            <svg className="w-4 h-4 text-red-600 dark:text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          )
        };
      default:
        return {
          color: 'text-gray-600 dark:text-gray-400',
          icon: (
            <svg className="w-4 h-4 text-gray-600 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          )
        };
    }
  };

  interface Column {
    key: keyof Contact;
    label: string;
    className?: string;
  }

  const columns: Column[] = [
    { key: 'displayName', label: 'Name' },
    { key: 'companyName', label: 'Company', className: 'hidden xl:table-cell' },
    { key: 'employeeStatus', label: 'Status' },
    { key: 'employeeType', label: 'Employee Type' },
    { key: 'email', label: 'Email', className: 'hidden md:table-cell' },
    { key: 'phone', label: 'Phone' }
  ];

  return (
    <div className="w-full bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 overflow-x-auto">
      <table className="w-full divide-y divide-gray-200 dark:divide-gray-700 min-w-[800px]">
        <thead>
          <tr className="bg-gray-50 dark:bg-gray-800">
            {columns.map(column => (
              <th
                key={column.key}
                scope="col"
                className={`px-6 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider cursor-pointer ${column.className || ''}`}
                onClick={() => {
                  if (sortColumn === column.key) {
                    onSort(column.key, sortDirection === 'asc' ? 'desc' : 'asc');
                  } else {
                    onSort(column.key, 'asc');
                  }
                }}
              >
                <div className="flex items-center">
                  {column.label}
                  {sortColumn === column.key && (
                    <span className="ml-1">
                      {sortDirection === 'asc' ? (
                        <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                        </svg>
                      ) : (
                        <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                      )}
                    </span>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
          {contacts.map((contact, index) => (
            <React.Fragment key={contact.id}>
              <tr
                ref={index === contacts.length - 1 ? lastElementCallback : null}
                className="hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
                onClick={() => onRowClick(contact.id)}
              >
                <td className="px-4 py-2 whitespace-nowrap">
                  <Link 
                    to={`/contacts/${contact.id}`}
                    className="text-xs text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
                  >
                    {contact.displayName}
                  </Link>
                </td>
                <td className="hidden xl:table-cell px-4 py-2 whitespace-nowrap">
                  <div className="text-xs text-gray-600 dark:text-gray-300">{contact.companyName}</div>
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  <div className="flex items-center gap-1.5">
                    {getStatusInfo(contact.employeeStatus).icon}
                    <span className={`text-xs ${getStatusInfo(contact.employeeStatus).color}`}>
                      {contact.employeeStatus}
                    </span>
                  </div>
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  <div className="text-xs text-gray-600 dark:text-gray-300">
                    {contact.employeeType || 'N/A'}
                  </div>
                </td>
                <td className="hidden md:table-cell px-4 py-2 whitespace-nowrap">
                  <div className="text-xs text-gray-600 dark:text-gray-300">{contact.email}</div>
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  <div className="text-xs text-gray-600 dark:text-gray-300">{contact.phone}</div>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {loading && (
        <div className="bg-white dark:bg-gray-800 px-6 py-4 border-t border-gray-200 dark:border-gray-700">
          <div className="flex justify-center">
            <div className="animate-pulse text-gray-500 dark:text-gray-400">Loading...</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactTable;
