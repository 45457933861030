import { Note, Task } from '../types';

interface TypeCounts {
  [key: string]: number;
}

interface Summary {
  totalNotes: number;
  byType: TypeCounts;
  recentActivity?: {
    title: string;
    createdAt: string;
  };
  textSummary: string;
  tasks: Task[];
}

const convertNoteToTask = (note: Note): Task => ({
  id: note.id,
  title: note.title,
  status: 'pending',
  dueDate: note.createdAt,
  assignee: '',
  priority: 'medium',
  contactId: note.contactId
});

export const generateSummary = (notes: Note[]): Summary => {
  const byType = notes.reduce((acc: TypeCounts, note: Note) => {
    acc[note.type] = (acc[note.type] || 0) + 1;
    return acc;
  }, {});

  const recentActivity = notes.length > 0
    ? notes.reduce((latest: Note, note: Note) =>
        new Date(note.createdAt).getTime() > new Date(latest.createdAt).getTime()
          ? note
          : latest
      )
    : undefined;

  const tasks = notes
    .filter(note => note.type === 'Task')
    .map(convertNoteToTask);

  return {
    totalNotes: notes.length,
    byType,
    recentActivity: recentActivity && {
      title: recentActivity.title,
      createdAt: recentActivity.createdAt
    },
    tasks,
    textSummary: generateTextSummary(notes)
  };
};

export const generateTextSummary = (notes: Note[]): string => {
  const sortedNotes = [...notes].sort((a: Note, b: Note) => 
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  const typeCounts = Object.entries(
    notes.reduce((acc: TypeCounts, note: Note) => {
      acc[note.type] = (acc[note.type] || 0) + 1;
      return acc;
    }, {})
  );

  return (
    'Summary:\n\n' +
    `Total Notes: ${notes.length}\n\n` +
    'By Type:\n' +
    typeCounts.map(([type, count]: [string, number]) => 
      `- ${count} ${type}${count > 1 ? 's' : ''}`
    ).join('\n') +
    '\n\n' +
    'Recent Activity:\n' +
    sortedNotes.slice(0, 3).map(note =>
      `- ${note.title} (${new Date(note.createdAt).toLocaleDateString()})`
    ).join('\n')
  );
};
