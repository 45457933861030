import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { contactService } from '../services/contactService';
import { mockNoteService } from '../services/mockNoteService';
import { generateSummary } from '../utils/summaryUtils';
import { Contact } from '../components/contact/types';
import ContactDetails from '../components/contact/ContactDetails';
import GmailImportModal from '../components/contact/GmailImportModal';
import TimelineEvents from '../components/contact/TimelineEvents';
import ActivitySummary from '../components/contact/ActivitySummary';
import NotesHistory from '../components/contact/NotesHistory';
import ContactInfo from '../components/contact/ContactInfo';
import { Note, Task, GmailMessage } from '../types';

interface SectionStates {
  contactInfo: boolean;
  timelineEvents: boolean;
  contactDetails: boolean;
  activitySummary: boolean;
  notesHistory: boolean;
}

interface Summary {
  totalNotes: number;
  byType: Record<string, number>;
  recentActivity?: {
    title: string;
    createdAt: string;
  };
  textSummary: string;
  tasks: Task[];
}

interface ContactPageProps {
  onContactSelect: (contact: Contact) => void;
}

const ContactPage: FC<ContactPageProps> = ({ onContactSelect }) => {
  const { id } = useParams<{ id: string }>();
  const [contact, setContact] = useState<Contact | null>(null);
  const [notes, setNotes] = useState<Note[]>([]);
  const [selectedType, setSelectedType] = useState<string>('All');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showGmailModal, setShowGmailModal] = useState<boolean>(false);
  const [notesSearchTerm, setNotesSearchTerm] = useState<string>('');
  const [summary, setSummary] = useState<Summary>({
    totalNotes: 0,
    byType: {},
    recentActivity: undefined,
    tasks: [],
    textSummary: ''
  });
  const [editingNoteId, setEditingNoteId] = useState<number | null>(null);
  const [sectionStates, setSectionStates] = useState<SectionStates>({
    contactInfo: false,
    timelineEvents: false,
    contactDetails: false,
    activitySummary: false,
    notesHistory: false
  });

  // Load section states from localStorage on mount
  useEffect(() => {
    const savedStates = localStorage.getItem('contactPageSectionStates');
    if (savedStates) {
      setSectionStates(JSON.parse(savedStates));
    }
  }, []);

  // Save section states to localStorage when they change
  useEffect(() => {
    localStorage.setItem('contactPageSectionStates', JSON.stringify(sectionStates));
  }, [sectionStates]);

  const toggleSection = (section: keyof SectionStates) => {
    setSectionStates((prev: SectionStates) => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  // Effect for fetching contact data
  useEffect(() => {
    const fetchData = async () => {
      if (!id) return;

      try {
        const contactId = parseInt(id);
        const contactData = await contactService.getContactById(contactId);

        if (contactData) {
          setContact(contactData);
          setNotes(contactData.notes || []);
          setSummary(generateSummary(contactData.notes || []) as Summary);
        }
      } catch (error) {
        console.error('Error fetching contact details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    void fetchData();
  }, [id]); // Only depend on id changes

  // Separate effect for updating navbar
  useEffect(() => {
    if (contact) {
      onContactSelect(contact);
    }
  }, [contact]); // Only run when contact data changes

  const handleGmailImport = (): void => {
    setShowGmailModal(true);
  };

  const handleImportSelected = async (messages: GmailMessage[]): Promise<void> => {
    if (!id || !contact) return;

    const contactId = parseInt(id);
    const importPromises = messages.filter(msg => msg.selected).map(msg =>
      mockNoteService.addNote({
        contactId,
        type: 'Email',
        title: msg.subject,
        content: msg.snippet,
        createdAt: new Date(msg.date).toISOString(),
        tags: ['Gmail Import'],
        visibility: msg.visibility === 'everyone' ? 'organization' : msg.visibility,
        status: 'Active',
        attachments: []
      })
    );

    const importedNotes = await Promise.all(importPromises);
    setNotes(prev => [...importedNotes, ...prev]);
    setShowGmailModal(false);
  };

  const handleEditNote = (noteId: number): void => {
    setEditingNoteId(noteId);
  };

  const handleSaveNote = async (noteId: number, updatedNote: Note): Promise<void> => {
    try {
      const savedNote = await mockNoteService.updateNote(noteId, updatedNote);
      setNotes(notes.map(note => note.id === noteId ? savedNote : note));
      setEditingNoteId(null);
    } catch (error) {
      console.error('Failed to update note:', error);
    }
  };

  const handleCancelEdit = (): void => {
    setEditingNoteId(null);
  };

  if (isLoading) return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );
  if (!contact) return <div>Contact not found</div>;

  return (
    <div className='py-6 px-8'>
      <div className="space-y-8">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
          <button
            onClick={() => toggleSection('contactInfo')}
            className="w-full p-4 flex justify-between items-center hover:bg-gray-50 dark:hover:bg-gray-700 rounded-t-lg text-gray-900 dark:text-gray-100"
          >
            <h2 className="text-xl font-semibold">Contact Information</h2>
            {sectionStates.contactInfo ? (
              <ChevronDownIcon className="h-5 w-5" />
            ) : (
              <ChevronUpIcon className="h-5 w-5" />
            )}
          </button>
          {!sectionStates.contactInfo && (
            <div className="p-4">
              <ContactInfo
                contact={contact}
                onGmailImport={handleGmailImport}
              />
            </div>
          )}
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
          <button
            onClick={() => toggleSection('timelineEvents')}
            className="w-full p-4 flex justify-between items-center hover:bg-gray-50 dark:hover:bg-gray-700 rounded-t-lg text-gray-900 dark:text-gray-100"
          >
            <h2 className="text-xl font-semibold">Timeline Events</h2>
            {sectionStates.timelineEvents ? (
              <ChevronDownIcon className="h-5 w-5" />
            ) : (
              <ChevronUpIcon className="h-5 w-5" />
            )}
          </button>
          {!sectionStates.timelineEvents && (
            <div className="p-4">
              <TimelineEvents contact={contact} />
            </div>
          )}
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
          <button
            onClick={() => toggleSection('contactDetails')}
            className="w-full p-4 flex justify-between items-center hover:bg-gray-50 dark:hover:bg-gray-700 rounded-t-lg text-gray-900 dark:text-gray-100"
          >
            <h2 className="text-xl font-semibold">Contact Details</h2>
            {sectionStates.contactDetails ? (
              <ChevronDownIcon className="h-5 w-5" />
            ) : (
              <ChevronUpIcon className="h-5 w-5" />
            )}
          </button>
          {!sectionStates.contactDetails && (
            <div className="p-4">
              <ContactDetails contact={contact} />
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
            <button
              onClick={() => toggleSection('activitySummary')}
              className="w-full p-4 flex justify-between items-center hover:bg-gray-50 dark:hover:bg-gray-700 rounded-t-lg text-gray-900 dark:text-gray-100"
            >
              <h2 className="text-xl font-semibold">Activity Summary</h2>
              {sectionStates.activitySummary ? (
                <ChevronDownIcon className="h-5 w-5" />
              ) : (
                <ChevronUpIcon className="h-5 w-5" />
              )}
            </button>
            {!sectionStates.activitySummary && (
              <div className="p-4">
                <ActivitySummary summary={summary} />
              </div>
            )}
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
            <button
              onClick={() => toggleSection('notesHistory')}
              className="w-full p-4 flex justify-between items-center hover:bg-gray-50 dark:hover:bg-gray-700 rounded-t-lg text-gray-900 dark:text-gray-100"
            >
              <h2 className="text-xl font-semibold">Notes History</h2>
              {sectionStates.notesHistory ? (
                <ChevronDownIcon className="h-5 w-5" />
              ) : (
                <ChevronUpIcon className="h-5 w-5" />
              )}
            </button>
            {!sectionStates.notesHistory && (
              <div className="p-4">
                <NotesHistory
                  notes={notes}
                  selectedType={selectedType}
                  notesSearchTerm={notesSearchTerm}
                  onSearchChange={setNotesSearchTerm}
                  onTypeSelect={setSelectedType}
                  editingNoteId={editingNoteId}
                  onEditNote={handleEditNote}
                  onSaveNote={handleSaveNote}
                  onCancelEdit={handleCancelEdit}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <GmailImportModal
        showModal={showGmailModal}
        onClose={() => setShowGmailModal(false)}
        onImport={handleImportSelected}
      />
    </div>
  );
};

export default ContactPage;
