import { FC, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { THEME_CLASSES, SIDEBAR } from '../constants/themeConstants';

interface NavigationItem {
  to: string;
  icon: ReactNode;
  label: string;
}

interface SidebarProps {
  isCollapsed: boolean;
  onCollapse: (collapsed: boolean) => void;
  onNavigate?: () => void;
}

const Sidebar: FC<SidebarProps> = ({ isCollapsed, onCollapse, onNavigate }) => {
  const location = useLocation();
  const isActive = (path: string): boolean => location.pathname === path;

  const navigationItems: NavigationItem[] = [
    { 
      to: '/', 
      icon: <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />, 
      label: 'Dashboard' 
    },
    {
      to: '/brokerages',
      icon: <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />,
      label: 'Brokerages'
    },    { 
      to: '/contacts', 
      icon: <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />, 
      label: 'Contacts' 
    },

    {
      to: '/pipelines',
      icon: <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />,
      label: 'Pipelines'
    },    { 
      to: '/chat', 
      icon: <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />, 
      label: 'Chat' 
    },
  ];

  return (
    <aside className={`h-screen border-r ${THEME_CLASSES.border} ${THEME_CLASSES.background} transition-all duration-300 ${
      isCollapsed ? SIDEBAR.collapsedWidth : SIDEBAR.expandedWidth
    }`}>
      <div className="flex flex-col h-full">
        {/* <div className="p-4 mt-3 mb-4">
          <Link to="/" className="flex items-center">
            <svg className="text-blue-500 w-8 h-8 ml-1.5" viewBox="0 0 24 24" fill="currentColor">
              <rect x="3" y="3" width="7" height="7" />
              <rect x="14" y="3" width="7" height="7" />
              <rect x="3" y="14" width="7" height="7" />
              <rect x="14" y="14" width="7" height="7" />
            </svg>
          </Link>
        </div> */}
        
        <nav className="flex-1 px-2 mt-4">
          <div className="flex flex-col space-y-1">
            {navigationItems.map(({ to, icon, label }) => (
              <Link 
                key={to}
                to={to}
                onClick={onNavigate}
                className={`flex items-center h-10 px-3 rounded-lg transition-colors duration-150 ${
                  isActive(to) 
                    ? 'bg-blue-600 text-white' 
                    : `${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background} ${THEME_CLASSES.hover.text}`
                }`}
              >
                <div className="w-6 h-6 flex items-center justify-center flex-shrink-0">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    {icon}
                  </svg>
                </div>
                <span className={`ml-3 text-sm font-medium whitespace-nowrap transition-opacity duration-150 ${
                  isCollapsed ? 'opacity-0 w-0 overflow-hidden' : 'opacity-100'
                }`}>
                  {label}
                </span>
              </Link>
            ))}
          </div>
        </nav>

        <div className="px-2 py-4 mt-auto">
          <button
            onClick={() => onCollapse(!isCollapsed)}
            className={`flex items-center p-2 rounded-lg ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.text} ${THEME_CLASSES.hover.background}`}
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              {isCollapsed ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              )}
            </svg>
          </button>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
