import { Brokerage } from '../components/brokerage/types';
import { getAuthHeaders } from './authService';

class BrokerageService {
  async getBrokerages(): Promise<Brokerage[]> {
    const headers = await getAuthHeaders();
    const response = await fetch('/api/brokerages', { headers });
    if (!response.ok) {
      throw new Error('Failed to fetch brokerages');
    }
    try {
      const data = await response.json();
      if (!Array.isArray(data)) {
        console.error('Expected array response but got:', data);
        return [];
      }
      return data;
    } catch (e) {
      console.error('Failed to parse response:', e);
      return [];
    }
  }

  async getBrokerage(id: number): Promise<Brokerage> {
    const headers = await getAuthHeaders();
    const response = await fetch(`/api/brokerages/${id}`, { headers });
    if (!response.ok) {
      throw new Error('Failed to fetch brokerage');
    }
    return response.json();
  }

  async createBrokerage(brokerage: Omit<Brokerage, 'id'>): Promise<Brokerage> {
    const headers = await getAuthHeaders();
    const response = await fetch('/api/brokerages', {
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(brokerage),
    });
    if (!response.ok) {
      throw new Error('Failed to create brokerage');
    }
    return response.json();
  }

  async updateBrokerage(id: number, brokerage: Partial<Brokerage>): Promise<Brokerage> {
    const headers = await getAuthHeaders();
    const response = await fetch(`/api/brokerages/${id}`, {
      method: 'PATCH',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(brokerage),
    });
    if (!response.ok) {
      throw new Error('Failed to update brokerage');
    }
    return response.json();
  }

  async deleteBrokerage(id: number): Promise<void> {
    const headers = await getAuthHeaders();
    const response = await fetch(`/api/brokerages/${id}`, {
      method: 'DELETE',
      headers,
    });
    if (!response.ok) {
      throw new Error('Failed to delete brokerage');
    }
  }
}

export const brokerageService = new BrokerageService();
