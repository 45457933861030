import React, { useState, useRef, useEffect } from 'react';

interface FilterButtonProps {
  availableCompanies: string[];
  selectedCompanies: Set<string>;
  statusFilter: string;
  onCompanySelect: (company: string) => void;
  onStatusChange: (status: string) => void;
  onClearAll: () => void;
}

export function FilterButton({
  availableCompanies,
  selectedCompanies,
  statusFilter,
  onCompanySelect,
  onStatusChange,
  onClearAll
}: FilterButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  const hasActiveFilters = selectedCompanies.size > 0 || statusFilter !== 'all';

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const filteredCompanies = availableCompanies.filter(company =>
    company.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`
          inline-flex items-center gap-2 px-4 py-2 border rounded-md text-xs font-medium
          ${hasActiveFilters
            ? 'bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 border-blue-200 dark:border-blue-800'
            : 'bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-200 border-gray-300 dark:border-gray-600'}
          hover:bg-gray-50 dark:hover:bg-gray-600
        `}
      >
        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
        </svg>
        Filter
        {hasActiveFilters && (
          <span className="px-2 py-0.5 bg-blue-500 dark:bg-blue-600 text-white rounded-full">
            {selectedCompanies.size + (statusFilter !== 'all' ? 1 : 0)}
          </span>
        )}
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-72 bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 shadow-lg z-50">
          {/* Status Filter Section */}
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
              Status
            </label>
            <select
              value={statusFilter}
              onChange={(e) => onStatusChange(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
            >
              <option value="all">All Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>

          {/* Company Filter Section */}
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
              Companies
            </label>
            <div className="relative mb-2">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search companies..."
                className="block w-full pl-8 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
              />
              <svg className="w-4 h-4 absolute left-2.5 top-2.5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
            <div className="max-h-48 overflow-y-auto">
              {filteredCompanies.map(company => (
                <label
                  key={company}
                  className={`
                    flex items-center gap-2 px-2 py-1.5 text-xs hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer rounded
                    ${selectedCompanies.has(company) ? 'bg-blue-50 dark:bg-blue-900/30' : ''}
                  `}
                >
                  <input
                    type="checkbox"
                    checked={selectedCompanies.has(company)}
                    onChange={() => onCompanySelect(company)}
                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <span className="flex-grow">{company}</span>
                </label>
              ))}
            </div>
          </div>

          {/* Footer */}
          {hasActiveFilters && (
            <div className="p-3 flex items-center justify-between">
              <span className="text-xs text-gray-500 dark:text-gray-400">
                {selectedCompanies.size + (statusFilter !== 'all' ? 1 : 0)} active filters
              </span>
              <button
                onClick={() => {
                  onClearAll();
                  onStatusChange('all');
                }}
                className="text-xs text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
              >
                Clear all filters
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
