import { FC, ChangeEvent } from 'react';
import { NOTE_TYPES } from '../../constants/noteConstants';
import EditableNote from './EditableNote';
import NoteCard from './NoteCard';
import { Note } from '../../types';

interface NotesHistoryProps {
  notes: Note[];
  selectedType: typeof NOTE_TYPES[number];
  notesSearchTerm: string;
  onSearchChange: (value: string) => void;
  onTypeSelect: (type: typeof NOTE_TYPES[number]) => void;
  editingNoteId: number | null;
  onEditNote: (noteId: number) => void;
  onSaveNote: (noteId: number, updatedNote: Note) => void;
  onCancelEdit: () => void;
}

const NotesHistory: FC<NotesHistoryProps> = ({
  notes,
  selectedType,
  notesSearchTerm,
  onSearchChange,
  onTypeSelect,
  editingNoteId,
  onEditNote,
  onSaveNote,
  onCancelEdit
}) => {
  const filteredNotes = notes
    .filter(note => selectedType === 'All' || note.type === selectedType)
    .filter(note =>
      note.title.toLowerCase().includes(notesSearchTerm.toLowerCase()) ||
      note.content.toLowerCase().includes(notesSearchTerm.toLowerCase()) ||
      note.tags.some(tag => tag.toLowerCase().includes(notesSearchTerm.toLowerCase()))
    );

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    onSearchChange(e.target.value);
  };

  const handleSaveNote = (note: Note) => {
    onSaveNote(note.id, note);
  };

  return (
    <div id="notes-history-container" className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700">
      <div id="notes-header-section" className="space-y-4 mb-6">
        <div id="notes-title-container" className="flex justify-between items-center">
          <h2 id="notes-history-title" className="text-xl font-bold text-gray-900 dark:text-white">Notes History</h2>
        </div>

        <div id="notes-controls" className="flex flex-col md:flex-row gap-4">
          <div id="search-container" className="relative flex-1">
            <div id="search-icon-container" className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
              <svg id="search-icon" className="h-4 w-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
            <input
              id="notes-search-input"
              type="search"
              placeholder="Search notes..."
              value={notesSearchTerm}
              onChange={handleSearchChange}
              className="block w-full pl-11 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
            />
          </div>

          <div id="type-filters" className="flex gap-2 overflow-x-auto">
            {NOTE_TYPES.map(type => (
              <button
                id={`type-filter-${type.toLowerCase()}`}
                key={type}
                onClick={() => onTypeSelect(type)}
                className={`px-3 py-1 rounded-full text-xs font-medium ${
                  selectedType === type
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
                }`}
              >
                {type}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div id="notes-list" className="divide-y divide-gray-200 dark:divide-gray-700 space-y-4">
        {filteredNotes.map(note => (
          <div id={`note-item-${note.id}`} key={note.id} className="pt-4 first:pt-0">
            {editingNoteId === note.id ? (
              <EditableNote
                note={note}
                onSave={handleSaveNote}
                onCancel={onCancelEdit}
              />
            ) : (
              <NoteCard
                note={note}
                isEditing={false}
                onEdit={() => onEditNote(note.id)}
                onSave={handleSaveNote}
                onCancel={onCancelEdit}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotesHistory;
