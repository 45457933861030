import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { contactService } from '../services/contactService';
import { companyService } from '../services/companyService';
import ContactDetails from '../components/contact/ContactDetails';
import ContactTable from '../components/contact/ContactTable';
import { Contact } from '../components/contact/types';
import { FilterButton } from '../components/FilterButton';
import { THEME_CLASSES } from '../constants/themeConstants';

function ContactSearch() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const searchValue = searchParams.get('search') || '';
  const setSearchQuery = (value: string) => {
    setSearchParams({ search: value });
  };
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [companyFilter, setCompanyFilter] = useState<Set<string>>(new Set());
  const [availableCompanies, setAvailableCompanies] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
  const observer = useRef<IntersectionObserver>();


  const toggleRow = (id: number): void => {
    setExpandedRows(prev => {
      const next = new Set(prev);
      if (next.has(id)) {
        next.delete(id);
      } else {
        next.add(id);
      }
      return next;
    });
  };

  const shouldLoadMore = useCallback(() => {
    if (loading) return false;

    // If we have filters active, check if we have shown all filtered results
    if (statusFilter !== 'all' || companyFilter.size > 0) {
      // If we haven't loaded all contacts yet, we should load more
      // This ensures we check all possible matches
      return hasMore;
    }

    // No filters - simple check if we have more unfiltered results
    return hasMore;
  }, [loading, hasMore, statusFilter, companyFilter.size]);

  const lastElementCallback = useCallback((node: HTMLElement | null) => {
    if (loading) return;

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && shouldLoadMore()) {
        setPage(prevPage => prevPage + 1);
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  }, [loading, shouldLoadMore]);

  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  const PAGE_SIZE = 100;

  useEffect(() => {
    const loadContacts = async () => {
      setLoading(true);
      try {
        const newContacts = await contactService.getAllContactsPaginated(page, PAGE_SIZE, searchValue);
        setContacts(prevContacts => {
          const updatedContacts = page === 1
            ? newContacts
            : [...prevContacts, ...newContacts];
          return Array.from(new Map(updatedContacts.map(c => [c.id, c])).values());
        });
        setHasMore(newContacts.length === PAGE_SIZE);
      } catch (error) {
        console.error('Failed to fetch contacts', error);
      } finally {
        setLoading(false);
      }
    };

    // Reset page when search changes
    setPage(1);
    loadContacts();
  }, [page, searchValue]);

  useEffect(() => {
    const filtered = contacts.filter(contact => {
      const matchesStatus = statusFilter === 'all' ||
        contact.employeeStatus.toLowerCase() === statusFilter.toLowerCase();
      const matchesCompany = companyFilter.size === 0 ||
        companyFilter.has(contact.companyName);
      return matchesStatus && matchesCompany;
    });
    setFilteredContacts(filtered);
  }, [contacts, statusFilter, companyFilter]);

  useEffect(() => {
    const loadCompanies = async () => {
      const companies = await companyService.getAllCompanies();
      const companyNames = companies
        .map(company => company.name)
        .filter(Boolean)
        .sort();
      setAvailableCompanies(companyNames);
    };

    loadCompanies();
  }, [contacts]);

  useEffect(() => {
    // Reset page when filters change
    setPage(1);
  }, [statusFilter, companyFilter]);

  const handleCompanyFilterChange = (company: string) => {
    setCompanyFilter(prev => {
      const next = new Set(prev);
      if (next.has(company)) {
        next.delete(company);
      } else {
        next.add(company);
      }
      return next;
    });
  };

  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-6">
        <h1 className={`text-2xl font-semibold ${THEME_CLASSES.text.primary}`}>Contacts</h1>
        <FilterButton
          statusFilter={statusFilter}
          onStatusChange={setStatusFilter}
          selectedCompanies={companyFilter}
          availableCompanies={availableCompanies}
          onCompanySelect={handleCompanyFilterChange}
          onClearAll={() => {
            setStatusFilter('all');
            setCompanyFilter(new Set());
          }}
        />
      </div>

      {(companyFilter.size > 0 || statusFilter !== 'all') && (
        <div className="flex flex-wrap items-center gap-2 mb-4">
          <span className="text-xs font-medium text-gray-500 dark:text-gray-400">
            Active filters:
          </span>
          {statusFilter !== 'all' && (
            <span className="inline-flex items-center gap-1 px-2 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full text-xs">
              Status: {statusFilter}
              <button
                onClick={() => setStatusFilter('all')}
                className="text-blue-600 dark:text-blue-300 hover:text-blue-800 dark:hover:text-blue-100"
              >
                <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </span>
          )}
          {Array.from(companyFilter).map(company => (
            <span
              key={company}
              className="inline-flex items-center gap-1 px-2 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full text-xs"
            >
              {company}
              <button
                onClick={() => handleCompanyFilterChange(company)}
                className="text-blue-600 dark:text-blue-300 hover:text-blue-800 dark:hover:text-blue-100"
              >
                <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </span>
          ))}
        </div>
      )}

      <ContactTable
        contacts={filteredContacts}
        loading={loading}
        hasMore={hasMore}
        expandedRows={expandedRows}
        lastElementCallback={lastElementCallback}
        onRowClick={toggleRow}
      />
    </div>
  );
}

export default ContactSearch;
