import { ChatMessage } from '../types';
import { getAuthHeaders } from './authService';

class ChatServiceError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ChatServiceError';
  }
}

interface StreamChunk {
  txt?: string;
  err?: string;
  func?: { name: string; args: any };
  done?: boolean;
}

class ChatService {
  private messages: ChatMessage[] = [];

  constructor() {
    const stored = localStorage.getItem('chat_messages');
    if (stored) {
      this.messages = JSON.parse(stored);
    }
  }

  async sendMessage(
    content: string,
    onChunk: (content: string) => void,
    onFunction: (func: { name: string; args: any }) => void,
    previousMessages: ChatMessage[] = []
  ): Promise<ChatMessage> {
    // Create user message
    const userMessage: ChatMessage = {
      id: Date.now(),
      role: 'user',
      content,
      timestamp: new Date().toISOString()
    };

    // Prepare messages array
    const messages = [...previousMessages, userMessage];
    let responseContent = '';

    return new Promise<ChatMessage>(async (resolve, reject) => {
      let eventSource: EventSource | null = null;
      let isClosing = false;

      // Cleanup function
      const cleanup = () => {
        if (!isClosing) {
          isClosing = true;
          if (eventSource) {
            eventSource.close();
            eventSource = null;
          }
          window.removeEventListener('beforeunload', cleanup);
          window.removeEventListener('unload', cleanup);
        }
      };

      try {
        // Step 1: Send initial message with auth
        const response = await fetch('/api/chat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...(await getAuthHeaders())
          },
          body: JSON.stringify({ messages })
        });

        // Handle response errors
        if (!response.ok) {
          const errorData = await response.json().catch(() => ({ message: response.statusText }));
          throw new Error(errorData.message || `Failed to send message: ${response.statusText}`);
        }

        // Get session ID
        const { sessionId } = await response.json();
        if (!sessionId) {
          throw new Error('No session ID received');
        }

        // Step 2: Setup SSE connection
        const sseUrl = `/api/chat?session=${sessionId}`;
        eventSource = new EventSource(sseUrl);

        // Handle successful connection
        eventSource.onopen = () => {
          window.addEventListener('beforeunload', cleanup);
          window.addEventListener('unload', cleanup);
        };

        // Handle incoming messages
        eventSource.onmessage = (event) => {
          try {
            const parsed: StreamChunk = JSON.parse(event.data);

            if (parsed.done) {
              const response: ChatMessage = {
                id: Date.now(),
                role: 'assistant',
                content: responseContent,
                timestamp: new Date().toISOString()
              };
              cleanup();
              resolve(response);
              return;
            }

            if (parsed.err) {
              cleanup();
              reject(new ChatServiceError(parsed.err || 'Unknown error occurred'));
              return;
            }

            if (parsed.func) {
              onFunction(typeof parsed.func === 'string' ? JSON.parse(parsed.func) : parsed.func);
            }

            if (parsed.txt !== undefined) {
              const chunk = parsed.txt.toString();
              onChunk(chunk);
              responseContent += chunk;
            }
          } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Failed to parse server response';
            cleanup();
            reject(new ChatServiceError(errorMessage));
          }
        };

        // Handle connection errors
        eventSource.onerror = (event) => {
          cleanup();
          reject(new ChatServiceError('Connection error occurred'));
        };

      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Failed to send message';
        cleanup();
        reject(new ChatServiceError(errorMessage));
      }
    });
  }

  getMessages(): ChatMessage[] {
    return this.messages;
  }

  saveMessages(messages: ChatMessage[]): void {
    this.messages = messages;
    localStorage.setItem('chat_messages', JSON.stringify(messages));
  }
}

export const chatService = new ChatService();
