import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { isAuthenticated as checkAuth } from './services/authService';
import Login from './pages/Login';
import Chat from './pages/Chat';
import Navbar from './components/Navbar';
import Dashboard from './pages/Dashboard';
import ContactSearch from './pages/ContactSearch';
import NoteSearch from './pages/NoteSearch';
import NoteDetail from './pages/NoteDetail';
import Brokerages from './pages/Brokerages';
import Pipelines from './pages/Pipelines';
import Sidebar from './components/Sidebar';
import { ThemeProvider, useTheme } from './context/ThemeContext';
import { THEME_COLORS, THEME_CLASSES, BREAKPOINTS } from './constants/themeConstants';
import { Contact } from './types';
import ContactPage from './pages/Contact';

// Wrapper component to access location
const AppContent: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isDark } = useTheme();
  const [selectedContact, setSelectedContact] = useState<Contact | undefined>();

  const handleContactSelect = (contact: Contact) => {
    setSelectedContact(contact);
  };

  // Update theme colors when theme changes
  useEffect(() => {
    // Update body background
    const backgroundColor = isDark ? THEME_COLORS.dark.background : THEME_COLORS.light.background;
    document.body.style.backgroundColor = backgroundColor;

    // Update theme-color meta tag
    const themeColorMeta = document.querySelector('meta[name="theme-color"]');
    if (themeColorMeta) {
      themeColorMeta.setAttribute('content', backgroundColor);
    } else {
      const meta = document.createElement('meta');
      meta.name = 'theme-color';
      meta.content = backgroundColor;
      document.head.appendChild(meta);
    }
  }, [isDark]);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(() => {
    const saved = localStorage.getItem('sidebarCollapsed');
    return saved ? JSON.parse(saved) : false;
  });

  const [isSidebarHidden, setIsSidebarHidden] = useState<boolean>(() => {
    const saved = localStorage.getItem('sidebarHidden');
    return saved ? JSON.parse(saved) : false;
  });

  useEffect(() => {
    setIsAuthenticated(checkAuth());

    // Navigate to last location if authenticated
    if (checkAuth()) {
      const lastLocation = localStorage.getItem('lastLocation');
      if (lastLocation && lastLocation !== location.pathname + location.search) {
        navigate(lastLocation);
      }
    }
  }, []);

  // Persist sidebar states
  useEffect(() => {
    localStorage.setItem('sidebarCollapsed', JSON.stringify(isSidebarCollapsed));
  }, [isSidebarCollapsed]);

  // Persist location
  useEffect(() => {
    localStorage.setItem('lastLocation', location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    localStorage.setItem('sidebarHidden', JSON.stringify(isSidebarHidden));
  }, [isSidebarHidden]);

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <div className={`h-screen flex ${THEME_CLASSES.background}`}>
      {!isSidebarHidden && (
        <Sidebar
          isCollapsed={isSidebarCollapsed}
          onCollapse={setIsSidebarCollapsed}
          onNavigate={() => {
            if (window.matchMedia(BREAKPOINTS.mobile).matches) {
              setIsSidebarHidden(true);
            }
          }}
        />
      )}

      {/* Main content area */}
      <div className={`flex-1 flex flex-col min-w-0`}>
        {/* Header */}
        <div className={`sticky top-0 z-40 ${THEME_CLASSES.border} ${THEME_CLASSES.background}`}>
          <div className="w-full flex items-center">
            <Navbar
              onToggleSidebar={() => setIsSidebarHidden(!isSidebarHidden)}
              currentContact={selectedContact}
            />
          </div>
        </div>

        {/* Main content */}
        <main className={`flex-1 ${THEME_CLASSES.background} overflow-y-auto`}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/contacts" element={<ContactSearch />} />
            <Route
              path="/contacts/:id"
              element={
                <ContactPage
                  onContactSelect={handleContactSelect}
                />
              }
            />
            <Route path="/notes" element={<NoteSearch />} />
            <Route path="/notes/:id" element={<NoteDetail />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/brokerages" element={<Brokerages />} />
            <Route path="/pipelines" element={<Pipelines />} />
          </Routes>
        </main>
      </div>
    </div>
  );
};

// Main App component
const App: React.FC = () => {
  return (
    <ThemeProvider>
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
};

export default App;
