import React, { FC, useState, useMemo, useEffect } from 'react';
import BrokerageDetails from './BrokerageDetails';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { Brokerage } from './types';

type SortDirection = 'asc' | 'desc';

interface Column {
  key: keyof Brokerage;
  label: string;
}

interface BrokerageTableProps {
  brokerages: Brokerage[];
  keywords: string[];
}

const PAGE_SIZE = 15;

const highlightText = (text: string, keywords: string[]): React.ReactNode => {
  if (!text || keywords.length === 0) return text;

  const regex = new RegExp(`(${keywords.map(k => k.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|')})`, 'gi');
  const parts = text.split(regex);

  return parts.map((part, i) =>
    keywords.includes(part.toLowerCase()) ? (
      <span key={i} className="bg-yellow-200 dark:bg-yellow-600">{part}</span>
    ) : (
      part
    )
  );
};

const columns: Column[] = [
  { key: 'name', label: 'Name' },
  { key: 'franchiseGroupName', label: 'Franchise Group' },
  { key: 'city', label: 'City' },
  { key: 'province', label: 'Province' },
  { key: 'activeAgentCount', label: 'Active Agents' },
  { key: 'locationCount', label: 'Locations' },
  { key: 'openDate', label: 'Open Date' },
  { key: 'contractEndDate', label: 'Contract End Date' }
];

const BrokerageTable: FC<BrokerageTableProps> = ({ brokerages, keywords }) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof Brokerage;
    direction: SortDirection;
  }>({
    key: 'name',
    direction: 'asc'
  });
  const [currentPage, setCurrentPage] = useState(0);

  // Reset page when search results change
  useEffect(() => {
    setCurrentPage(0);
  }, [brokerages.length]);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const sortedBrokerages = useMemo(() => {
    if (!Array.isArray(brokerages) || !brokerages.length) {
      return [];
    }
    
    const sorted = [...brokerages];
    const { key, direction } = sortConfig;
    const multiplier = direction === 'asc' ? 1 : -1;

    sorted.sort((a, b) => {
      const aValue = a[key];
      const bValue = b[key];

      if (aValue === null || aValue === undefined) return 1 * multiplier;
      if (bValue === null || bValue === undefined) return -1 * multiplier;

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue) * multiplier;
      }

      return ((aValue as number) - (bValue as number)) * multiplier;
    });

    return sorted;
  }, [brokerages, sortConfig]);

  const paginatedBrokerages = useMemo(() => {
    const start = currentPage * PAGE_SIZE;
    return sortedBrokerages.slice(start, start + PAGE_SIZE);
  }, [sortedBrokerages, currentPage]);

  const totalPages = Math.ceil(sortedBrokerages.length / PAGE_SIZE);

  const handleSort = (key: keyof Brokerage) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full divide-y divide-gray-200 dark:divide-gray-800">
        <thead className={THEME_CLASSES.background}>
          <tr>
            {columns.map(column => (
              <th
                key={column.key}
                onClick={() => handleSort(column.key)}
                className={`px-6 py-2 text-left text-xs font-medium uppercase tracking-wider cursor-pointer ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.text}`}
              >
                <div className="flex items-center space-x-1">
                  <span>{column.label}</span>
                  {sortConfig.key === column.key && (
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      {sortConfig.direction === 'asc' ? (
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                      ) : (
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      )}
                    </svg>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={`divide-y divide-gray-200 dark:divide-gray-800 ${THEME_CLASSES.background}`}>
          {paginatedBrokerages.map((brokerage) => (
            <React.Fragment key={brokerage.id}>
              <tr
                onClick={() => setExpandedRow(expandedRow === brokerage.id ? null : brokerage.id)}
                className={`${THEME_CLASSES.hover.background} cursor-pointer`}
              >
                {columns.map(column => (
                  <td
                    key={column.key}
                    className={`px-6 py-2.5 whitespace-nowrap text-xs ${
                      column.key === 'name' 
                        ? THEME_CLASSES.text.primary 
                        : THEME_CLASSES.text.secondary
                    }`}
                  >
                    {column.key === 'openDate' || column.key === 'contractEndDate'
                      ? formatDate(brokerage[column.key] || '')
                      : highlightText(String(brokerage[column.key] || ''), keywords)}
                  </td>
                ))}
              </tr>
              {expandedRow === brokerage.id && (
                <tr>
                  <td colSpan={columns.length} className="px-6 py-4">
                    <BrokerageDetails brokerage={brokerage} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      
      {totalPages > 1 && (
        <div className="flex justify-center mt-4">
          <button
            onClick={() => setCurrentPage(p => Math.max(0, p - 1))}
            disabled={currentPage === 0}
            className="px-4 py-2 mr-2 text-sm font-medium rounded-md bg-gray-100 dark:bg-gray-700 disabled:opacity-50"
          >
            Previous
          </button>
          <span className="px-4 py-2 text-sm">
            Page {currentPage + 1} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage(p => Math.min(totalPages - 1, p + 1))}
            disabled={currentPage === totalPages - 1}
            className="px-4 py-2 ml-2 text-sm font-medium rounded-md bg-gray-100 dark:bg-gray-700 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default BrokerageTable;
