import React, { useState, useEffect, useRef } from 'react';
import useDebounce from '../hooks/useDebounce';
import { NOTE_TYPES, /*NOTE_VISIBILITY_OPTIONS*/ } from '../constants/noteConstants';
import { Contact } from '../types';
import { contactService } from '../services/contactService';

interface NewNoteProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  initialContact?: Contact;
}

type NoteType = typeof NOTE_TYPES[number];
type VisibilityOption = 'me' | 'team' | 'organization';

interface FormState {
  title: string;
  content: string;
  type: NoteType;
  contactIds: string[];
  tags: string[];
  attachments: {
    id: number;
    name: string;
    url: string;
    type: string;
  }[];
  visibility: VisibilityOption;
  emailContacts: boolean;
}

const NewNote: React.FC<NewNoteProps> = ({ isSidebarOpen, toggleSidebar, initialContact }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [isSearching, setIsSearching] = useState(false);
//  const [newTag, setNewTag] = useState<string>(''); // Added missing state
  const searchRef = useRef<HTMLDivElement>(null);

  const [form, setForm] = useState<FormState>({
    title: '',
    content: '',
    type: 'Note',
    contactIds: [],
    tags: [],
    attachments: [],
    visibility: 'me',
    emailContacts: false,
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const searchContacts = async () => {
      if (debouncedSearchTerm.length >= 2) {
        setIsSearching(true);
        try {
          const results = await contactService.searchContacts(debouncedSearchTerm);
          setFilteredContacts(results);
          setIsDropdownOpen(true);
        } catch (error) {
          console.error('Failed to search contacts:', error);
          setFilteredContacts([]); // Clear results on error
        } finally {
          setIsSearching(false);
        }
      } else {
        setFilteredContacts([]);
        setIsDropdownOpen(false);
      }
    };

    void searchContacts();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    console.log('initialContact.id:', initialContact?.id);
    if (initialContact && !selectedContacts.some(c => c.id === initialContact.id)) {
      setSelectedContacts([initialContact]);
      setForm(prev => ({
        ...prev,
        contactIds: [initialContact.id.toString()]
      }));
    }
  }, [initialContact]);

  const handleSearchFocus = () => {
    if (filteredContacts.length > 0) {
      setIsDropdownOpen(true);
    }
  };

  const handleContactSelect = (contact: Contact): void => {
    if (!selectedContacts.some(c => c.id === contact.id)) {
      setSelectedContacts(prev => [...prev, contact]);
      setForm(prev => ({
        ...prev,
        contactIds: [...prev.contactIds, contact.id.toString()]
      }));
    }
    setSearchTerm('');
    setIsDropdownOpen(false);
  };

  const handleRemoveContact = (contactId: number): void => {
    setSelectedContacts(prev => prev.filter(c => c.id !== contactId));
    setForm(prev => ({
      ...prev,
      contactIds: prev.contactIds.filter(id => id !== contactId.toString())
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (form.contactIds.length === 0) {
      alert('Please select at least one contact');
      return;
    }

    try {
      const emailList = selectedContacts
        .map(contact => contact.email)
        .filter(email => email) // Remove any undefined/null emails
        .join(',');

      const noteData = {
        title: form.title,
        content: form.content,
        creatorID: 3,
        emailList: emailList || 'eugenklein@royallepage.ca',
        type: form.type,
        visibility: 1,//form.visibility,
        tags: form.tags
      };

      const newNote = await contactService.createNote(noteData);
      console.log(newNote);
      toggleSidebar();
      //navigate(`/notes/${newNote.id}`);
    } catch (error) {
      console.error('Failed to create note:', error);
      alert('Failed to create note. Please try again.');
    }
  };
/*
  const handleAddTag = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (newTag && !form.tags.includes(newTag)) {
      setForm(prev => ({
        ...prev,
        tags: [...prev.tags, newTag]
      }));
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove: string): void => {
    setForm(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };
*/
  return (
    <>
      <div className={`fixed inset-0 z-40 ${isSidebarOpen ? '' : 'pointer-events-none'}`}>
        <div
          className={`absolute inset-0 transition-opacity duration-300 ${isSidebarOpen ? 'opacity-50' : 'opacity-0'}`}
          onClick={toggleSidebar}
        >
          <div className="absolute inset-0 bg-black"></div>
        </div>
        <div
          className={`fixed inset-y-0 right-0 w-full md:w-[40rem] bg-white dark:bg-gray-800 shadow-xl transform transition-transform duration-300 ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'}`}
        >
          <div className="p-6 border-b dark:border-gray-700">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Add New Note</h2>
              <button
                onClick={toggleSidebar}
                className="text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors duration-200"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
          <div className="p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Title */}
              <div>
                <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
                  Title
                </label>
                <input
                  type="text"
                  required
                  value={form.title}
                  onChange={(e) => setForm(prev => ({ ...prev, title: e.target.value }))}
                  className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
                  placeholder="Enter note title"
                />
              </div>

              {/* Content */}
              <div>
                <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
                  Content
                </label>
                <textarea
                  required
                  value={form.content}
                  onChange={(e) => setForm(prev => ({ ...prev, content: e.target.value }))}
                  className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
                  rows={4}
                  placeholder="Enter note content"
                />
              </div>

              {/* Type */}
              <div>
                <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
                  Type
                </label>
                <select
                  value={form.type}
                  onChange={(e) => setForm(prev => ({ ...prev, type: e.target.value as NoteType }))}
                  className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
                >
                  {NOTE_TYPES.filter(type => type !== 'All').map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>

              {/* Contact */}
              <div>
                <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
                  Contact
                </label>
                <div className="relative" ref={searchRef}>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onFocus={handleSearchFocus}
                    className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
                    placeholder="Start typing to search contacts (minimum 2 characters)..."
                  />
                  {isSearching && (
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                      <svg className="animate-spin h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </div>
                  )}
                  {isDropdownOpen && filteredContacts.length > 0 && (
                    <div className="absolute z-50 w-full mt-1 bg-white dark:bg-gray-800 rounded-md shadow-lg border border-gray-200 dark:border-gray-700 max-h-60 overflow-auto">
                      {filteredContacts.map(contact => (
                        <div
                          key={contact.id}
                          onClick={() => handleContactSelect(contact)}
                          className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer text-xs text-gray-900 dark:text-gray-100"
                        >
                          {contact.displayName}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {/* Selected Contacts List */}
              <div className="mt-2 flex flex-wrap gap-2">
                {selectedContacts.map(contact => (
                  <span
                    key={contact.id}
                    className="px-2 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full text-xs flex items-center"
                  >
                    {contact.displayName}
                    <button
                      type="button"
                      onClick={() => handleRemoveContact(contact.id)}
                      className="ml-1 text-blue-600 dark:text-blue-400 hover:text-blue-800 transition-colors duration-200"
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
{/* /*
              {/* Email Contacts Checkbox *}
              {selectedContacts.length > 0 && (
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="emailContacts"
                    checked={form.emailContacts}
                    onChange={(e) => setForm(prev => ({ ...prev, emailContacts: e.target.checked }))}
                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label htmlFor="emailContacts" className="ml-2 block text-xs font-medium text-gray-700 dark:text-gray-200">
                    Email this note to selected contacts
                  </label>
                </div>
              )}

              {/* Visibility *}
              <div>
                <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
                  Visibility
                </label>
                <div className="space-y-2">
                  {NOTE_VISIBILITY_OPTIONS.map(option => (
                    <div key={option.value} className="flex items-center">
                      <input
                        type="radio"
                        id={`visibility-${option.value}`}
                        name="visibility"
                        value={option.value}
                        checked={form.visibility === option.value}
                        onChange={(e) => setForm(prev => ({ ...prev, visibility: e.target.value as VisibilityOption }))}
                        className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                      />
                      <label htmlFor={`visibility-${option.value}`} className="ml-2 block text-xs font-medium text-gray-700 dark:text-gray-200">
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* Tags *}
              <div>
                <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
                  Tags
                </label>
                <div className="flex flex-wrap gap-2 mb-2">
                  {form.tags.map(tag => (
                    <span
                      key={tag}
                      className="px-2 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full text-xs flex items-center"
                    >
                      {tag}
                      <button
                        type="button"
                        onClick={() => handleRemoveTag(tag)}
                        className="ml-1 text-blue-600 dark:text-blue-400 hover:text-blue-800 transition-colors duration-200"
                      >
                        ×
                      </button>
                    </span>
                  ))}
                </div>
                <div className="flex gap-2">
                  <input
                    type="text"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    className="block flex-1 px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
                    placeholder="Add a tag"
                  />
                  <button
                    type="button"
                    onClick={handleAddTag}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-blue-600 hover:bg-blue-700"
                  >
                    Add
                  </button>
                </div>
              </div>
*/}
              {/* Submit Button */}
              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  onClick={toggleSidebar}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-xs font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-blue-600 hover:bg-blue-700"
                >
                  Create Note
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewNote;
