import { GmailMessage } from '../types';

export const NOTE_TYPES = ['All', 'Finance', 'Legal', 'Sales', 'Marketing', 'Support'];

export const NOTE_TYPE_COLORS = {
  Finance: 'bg-green-600 text-white',
  Legal: 'bg-purple-600 text-white',
  Sales: 'bg-blue-600 text-white',
  Marketing: 'bg-orange-600 text-white',
  Support: 'bg-red-600 text-white',
  All: 'bg-gray-600 text-white'
};

export const NOTE_VISIBILITY_OPTIONS = [
  { value: 'me', label: 'Me Only', icon: '👤' },
  { value: 'team', label: 'My Team', icon: '👥' },
  { value: 'organization', label: 'Organization', icon: '🌎' }
];

// Mock Gmail messages - in real app, this would come from an API
export const MOCK_GMAIL_MESSAGES: GmailMessage[] = [
  {
    id: 1,
    subject: 'Q4 Financial Report',
    from: 'finance@example.com',
    to: 'me@example.com',
    cc: 'team@example.com',
    date: '2024-01-15',
    snippet: 'Please review the Q4 financial statements...',
    isFromContact: true,
    selected: false,
    visibility: 'me'
  },
  {
    id: 2,
    subject: 'Contract Review Required',
    from: 'legal@example.com',
    to: 'me@example.com',
    date: '2024-01-14',
    snippet: 'The new vendor agreement needs review...',
    isFromContact: false,
    selected: false,
    visibility: 'me'
  },
  {
    id: 3,
    subject: 'Marketing Campaign Results',
    from: 'marketing@example.com',
    to: 'me@example.com',
    cc: 'manager@example.com',
    date: '2024-01-13',
    snippet: 'Here are the results from our latest campaign...',
    isFromContact: true,
    selected: false,
    visibility: 'me'
  }
];
