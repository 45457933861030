import { useState, useRef, useEffect, FC, KeyboardEvent, MouseEvent, ChangeEvent } from 'react';
import { mockGmailService } from '../../services/mockGmailService';
import { GmailMessage } from '../../types';

interface GmailImportModalProps {
  showModal: boolean;
  onClose: () => void;
  onImport: (messages: GmailMessage[]) => void;
}

const GmailImportModal: FC<GmailImportModalProps> = ({ showModal, onClose, onImport }) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [messages, setMessages] = useState<GmailMessage[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (showModal) {
      dialogRef.current?.showModal();
      void fetchMessages();
    } else {
      dialogRef.current?.close();
    }
  }, [showModal]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      void fetchMessages();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const fetchMessages = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const fetchedMessages = await mockGmailService.getMessages(searchTerm);
      setMessages(fetchedMessages);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
    setIsLoading(false);
  };

  const handleMessageSelect = (messageId: number): void => {
    setMessages(messages.map(msg => 
      msg.id === messageId 
        ? { ...msg, selected: !msg.selected }
        : msg
    ));
  };

  const handleVisibilityChange = (messageId: number, visibility: GmailMessage['visibility']): void => {
    setMessages(messages.map(msg => 
      msg.id === messageId 
        ? { ...msg, visibility }
        : msg
    ));
  };

  const handleImportSelected = async (): Promise<void> => {
    const selectedMessageIds = messages
      .filter(msg => msg.selected)
      .map(msg => msg.id);

    if (selectedMessageIds.length === 0) return;

    try {
      const result = await mockGmailService.importMessages(selectedMessageIds);
      if (result.success) {
        onImport(result.messages);
        onClose();
      }
    } catch (error) {
      console.error('Error importing messages:', error);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDialogElement>): void => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  const handleDialogClick = (e: MouseEvent<HTMLDialogElement>): void => {
    if (e.target === dialogRef.current) {
      onClose();
    }
  };

  if (!showModal) return null;

  return (
    <dialog
      ref={dialogRef}
      className="backdrop:bg-black/50 backdrop:backdrop-blur-sm modal fixed rounded-lg shadow-xl w-[95vw] h-[90vh] md:w-[90vw] md:h-[85vh] lg:w-[1200px] p-0 bg-white dark:bg-gray-800 border-0 m-auto backdrop:backdrop-opacity-100"
      onClose={onClose}
      onClick={handleDialogClick}
      onKeyDown={handleKeyDown}
      aria-labelledby="dialog-title"
      aria-modal="true"
    >
      <div className="flex flex-col h-full">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
          <h2 id="dialog-title" className="text-lg font-semibold text-gray-900 dark:text-gray-100">
            Import from Gmail
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Search */}
        <div className="p-4 border-b border-gray-200 dark:border-gray-700">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
              <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
            <input
              type="text"
              value={searchTerm}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
              placeholder="Search emails..."
              className="block w-full pl-11 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
            />
          </div>
        </div>

        {/* Message List */}
        <div className="flex-1 overflow-y-auto">
          {isLoading ? (
            <div className="p-4 text-center text-gray-500 dark:text-gray-400">
              Loading messages...
            </div>
          ) : messages.length === 0 ? (
            <div className="p-4 text-center text-gray-500 dark:text-gray-400">
              No messages found
            </div>
          ) : (
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              {messages.map((message) => (
                <li
                  key={message.id}
                  className={`group p-4 hover:bg-gray-50 dark:hover:bg-gray-700/50 cursor-pointer transition-colors duration-150 ${
                    message.selected ? 'bg-blue-50 dark:bg-blue-900/50' : ''
                  }`}
                >
                  <div className="flex items-start justify-between space-x-4">
                    {/* Message Content and Checkbox */}
                    <div className="flex items-start flex-1 min-w-0 space-x-4">
                      <div className="pt-1">
                        <input
                          type="checkbox"
                          checked={message.selected}
                          onChange={() => handleMessageSelect(message.id)}
                          className="w-4 h-4 rounded border-gray-300 dark:border-gray-600 text-blue-600 focus:ring-blue-500 dark:bg-gray-700 dark:checked:bg-blue-600"
                        />
                      </div>

                      <div 
                        className="flex-1 min-w-0"
                        onClick={() => handleMessageSelect(message.id)}
                      >
                        {/* Subject and Date */}
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-2">
                            {message.isFromContact ? (
                              <svg className="h-4 w-4 text-green-500 shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4m0 0l6-6m-6 6l6 6" />
                              </svg>
                            ) : (
                              <svg className="h-4 w-4 text-blue-500 shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 12h16m0 0l-6-6m6 6l-6 6" />
                              </svg>
                            )}
                            <p className="text-sm font-medium text-gray-900 dark:text-gray-100 truncate">
                              {message.subject}
                            </p>
                          </div>
                          <span className="text-xs text-gray-500 dark:text-gray-400 whitespace-nowrap ml-2">
                            {new Date(message.date).toLocaleDateString()}
                          </span>
                        </div>
                        
                        {/* Email Addresses */}
                        <div className="mt-1 flex items-center space-x-2">
                          <div className="flex flex-col space-y-1">
                            <div className="flex items-center space-x-1">
                              <span className="text-xs font-medium text-gray-500 dark:text-gray-400">From:</span>
                              <span className="text-xs text-gray-600 dark:text-gray-300">{message.from}</span>
                            </div>
                            <div className="flex items-center space-x-1">
                              <span className="text-xs font-medium text-gray-500 dark:text-gray-400">To:</span>
                              <span className="text-xs text-gray-600 dark:text-gray-300">{message.to}</span>
                            </div>
                            {message.cc && (
                              <div className="flex items-center space-x-1">
                                <span className="text-xs font-medium text-gray-500 dark:text-gray-400">CC:</span>
                                <span className="text-xs text-gray-600 dark:text-gray-300">{message.cc}</span>
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Email Snippet */}
                        <div className="mt-3 px-4 py-3 bg-gray-50 dark:bg-gray-700/50 rounded-md">
                          <pre className="whitespace-pre-wrap break-words text-xs leading-6 text-gray-600 dark:text-gray-300 font-normal">
                            {message.snippet}
                          </pre>
                        </div>
                      </div>
                    </div>

                    {/* Visibility Radio Buttons */}
                    {message.selected && (
                      <div className="flex flex-col space-y-2 min-w-[100px] pl-4 border-l border-gray-200 dark:border-gray-700">
                        <label className="flex items-center space-x-2 cursor-pointer">
                          <input
                            type="radio"
                            name={`visibility-${message.id}`}
                            value="me"
                            checked={message.visibility === 'me'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => 
                              handleVisibilityChange(message.id, e.target.value as GmailMessage['visibility'])
                            }
                            className="w-3 h-3 text-gray-600 dark:text-gray-400"
                          />
                          <span className="text-xs text-gray-600 dark:text-gray-400">Only me</span>
                        </label>
                        <label className="flex items-center space-x-2 cursor-pointer">
                          <input
                            type="radio"
                            name={`visibility-${message.id}`}
                            value="team"
                            checked={message.visibility === 'team'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => 
                              handleVisibilityChange(message.id, e.target.value as GmailMessage['visibility'])
                            }
                            className="w-3 h-3 text-blue-600 dark:text-blue-400"
                          />
                          <span className="text-xs text-gray-600 dark:text-gray-400">Team</span>
                        </label>
                        <label className="flex items-center space-x-2 cursor-pointer">
                          <input
                            type="radio"
                            name={`visibility-${message.id}`}
                            value="everyone"
                            checked={message.visibility === 'everyone'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => 
                              handleVisibilityChange(message.id, e.target.value as GmailMessage['visibility'])
                            }
                            className="w-3 h-3 text-green-600 dark:text-green-400"
                          />
                          <span className="text-xs text-gray-600 dark:text-gray-400">Everyone</span>
                        </label>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Footer */}
        <div className="p-4 border-t border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800">
          <div className="flex justify-end space-x-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md transition-colors duration-150"
            >
              Cancel
            </button>
            <button
              onClick={() => void handleImportSelected()}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md transition-colors duration-150"
            >
              Import Selected
            </button>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default GmailImportModal;
