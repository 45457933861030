import { FC } from 'react';
import { Task } from '../../types';

interface ActivitySummaryProps {
  summary: {
    totalNotes: number;
    byType: Record<string, number>;
    recentActivity?: {
      title: string;
      createdAt: string;
    };
    textSummary: string;
    tasks: Task[];
  }
}

const ActivitySummary: FC<ActivitySummaryProps> = ({ summary }) => {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700">
      <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-4">Activity Summary</h2>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Statistics Column */}
        <div className="space-y-4">
          <div>
            <h3 className="text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">Note Statistics</h3>
            <div className="flex flex-wrap gap-4">
              <div className="bg-blue-50 dark:bg-blue-900/30 p-3 rounded-lg">
                <p className="text-xs text-gray-600 dark:text-gray-300">Total Notes</p>
                <p className="text-lg font-semibold text-blue-600 dark:text-blue-400">{summary.totalNotes}</p>
              </div>
              {Object.entries(summary.byType).map(([type, count]) => (
                <div key={type} className="bg-blue-50 dark:bg-blue-900/30 p-3 rounded-lg">
                  <p className="text-xs text-gray-600 dark:text-gray-300">{type}</p>
                  <p className="text-lg font-semibold text-blue-600 dark:text-blue-400">{count}</p>
                </div>
              ))}
            </div>
          </div>

          {summary.recentActivity && (
            <div>
              <h3 className="text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">Last Activity</h3>
              <div className="bg-gray-50 dark:bg-gray-700/50 p-3 rounded-lg">
                <p className="text-xs font-medium text-gray-900 dark:text-white">{summary.recentActivity.title}</p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  {new Date(summary.recentActivity.createdAt).toLocaleDateString()}
                </p>
              </div>
            </div>
          )}
        </div>

        {/* Summary and Tasks Column */}
        <div className="lg:col-span-2 space-y-6">
          {/* Contact Summary */}
          <div>
            <h3 className="text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">Contact Summary</h3>
            <div className="bg-gray-50 dark:bg-gray-700/50 p-4 rounded-lg">
              <pre className="text-xs text-gray-600 dark:text-gray-300 whitespace-pre-wrap font-sans">
                {summary.textSummary}
              </pre>
            </div>
          </div>

          {/* Tasks */}
          <div>
            <h3 className="text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">Related Tasks</h3>
            <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4">
              <div className="divide-y divide-gray-200 dark:divide-gray-700">
                {summary.tasks.length > 0 ? (
                  summary.tasks.map(task => (
                    <div key={task.id} className="py-2 first:pt-0 last:pb-0">
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          className="h-4 w-4 text-blue-600 rounded border-gray-300"
                          checked={task.status === 'completed'}
                          onChange={() => {
                            // Add task status toggle logic here if needed
                          }}
                        />
                        <span className="text-xs text-gray-600 dark:text-gray-300">{task.title}</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-xs text-gray-500 dark:text-gray-400">No tasks generated</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivitySummary;
