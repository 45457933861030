import { useState, useEffect, useRef, FormEvent } from 'react';
import ReactMarkdown from 'react-markdown';
import { chatService } from '../services/chatService';
import { ChatMessage } from '../types';

const MAX_MESSAGES = 10;
const MAX_CONTENT_LENGTH = 1024;

const Chat = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [streamingContent, setStreamingContent] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const loadMessages = async () => {
      setIsLoading(true);
      try {
        const loadedMessages = await chatService.getMessages();
        setMessages(loadedMessages);
      } catch (error) {
        console.error('Failed to load messages:', error);
      }
      setIsLoading(false);
    };

    loadMessages();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, streamingContent]);

  // Get last N messages that fit within maxLength
  const getLastMessagesWithinLimit = (messages: ChatMessage[], maxLength: number = MAX_CONTENT_LENGTH): ChatMessage[] => {
    let totalLength = 0;
    const filteredMessages: ChatMessage[] = [];
    
    // Get only user and assistant messages (exclude function messages)
    const conversationMessages = messages.filter(msg => msg.role === 'user' || msg.role === 'assistant');
    
    // Start from the end and work backwards
    for (let i = conversationMessages.length - 1; i >= 0 && filteredMessages.length < MAX_MESSAGES; i--) {
      const messageLength = conversationMessages[i].content.length;
      if (totalLength + messageLength <= maxLength) {
        filteredMessages.unshift(conversationMessages[i]); // Add to start to maintain order
        totalLength += messageLength;
      } else {
        break;
      }
    }
    
    return filteredMessages;
  };

  const handleSendMessage = async (e: FormEvent) => {
    e.preventDefault();
    if (!inputValue.trim()) return;

    const userMessage: ChatMessage = {
      id: Date.now(),
      role: 'user',
      content: inputValue,
      timestamp: new Date().toISOString()
    };

    // Add user message to local state and save to storage
    const newMessagesWithUser = [...messages, userMessage];
    setMessages(newMessagesWithUser);
    chatService.saveMessages(newMessagesWithUser);
    setInputValue('');
    setIsLoading(true);

    const sendMessageWithRetry = async () => {
      try {
        setStreamingContent('');
        setError(null);
        
        // Get last messages within 1024 character limit
        const lastMessages = getLastMessagesWithinLimit(newMessagesWithUser);
        
        const response = await chatService.sendMessage(
          inputValue,
          (chunk: string) => {
            setStreamingContent(prev => prev + chunk);
          },
          (func: { name: string; args: any }) => {
            console.log('Function call:', func.name, 'with args:', func.args);
          },
          lastMessages
        );
        
        // After SSE is done, format the response and update UI
        const formattedResponse = {
          ...response,
          content: response.content
        };
        
        // Update messages with formatted AI response and save to storage
        const newMessages = [...newMessagesWithUser, formattedResponse];
        setMessages(newMessages);
        chatService.saveMessages(newMessages);
        
        // Clear streaming content after updating messages
        setStreamingContent('');
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
        setError(errorMessage);
        setStreamingContent('');
        
        if (errorMessage.includes('session expired')) {
          setTimeout(() => setError(null), 5000);
        }
      } finally {
        // Always re-enable send button and clear loading state
        setIsLoading(false);
      }
    };

    // Execute the send message function
    sendMessageWithRetry();
  };

  return (
    <div className="h-[calc(100vh-64px)]">
      <div className="max-w-4xl w-full mx-auto h-full bg-white dark:bg-gray-800 shadow-lg flex flex-col">
        {/* Header */}
        <div className="flex-none bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center justify-between px-6 pt-6 pb-4">
              <div className="flex items-center gap-2">
                <svg className="w-5 h-5 text-gray-700 dark:text-gray-200" viewBox="0 0 24 24">
                  <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 2.18l7 3.12v4.7c0 4.83-3.4 9.36-7 10.6-3.6-1.24-7-5.77-7-10.6V6.3l7-3.12z" fill="currentColor"/>
                </svg>
                <span className="text-lg font-medium text-gray-900 dark:text-white">Chat with AI</span>
              </div>
              <button
                onClick={() => {
                  setMessages([]);
                  chatService.saveMessages([]);
                }}
                className="px-3 py-1.5 text-sm font-medium text-gray-700 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-md transition-colors"
              >
                New Chat
              </button>
            </div>
        </div>

        {/* Scrollable content area */}
        <div className="flex-1 overflow-y-auto">
          {/* Welcome and suggestion cards */}
          <div className="px-6 py-6 space-y-4">
            {/* Welcome message */}
            <div className="text-sm text-gray-600 dark:text-gray-300">How can I help you today?</div>
            
            {/* Suggestion cards - grid layout */}
            <div className="grid grid-cols-2 gap-4">
              {/* First suggestion card */}
              <button 
                onClick={() => setInputValue("Summarize my recent notes")}
                className="bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 p-6 rounded-lg border border-gray-300 dark:border-gray-600 flex flex-col items-center gap-3 transition-colors text-center">
                <div className="bg-gray-50 dark:bg-gray-800 p-3 rounded-md">
                  <svg className="w-6 h-6 text-gray-600 dark:text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-900 dark:text-white">Summarize</div>
                  <div className="text-sm text-gray-600 dark:text-gray-300">from notes</div>
                </div>
              </button>
              
              {/* Second suggestion card */}
              <button 
                onClick={() => setInputValue("List action items from my notes")}
                className="bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 p-6 rounded-lg border border-gray-300 dark:border-gray-600 flex flex-col items-center gap-3 transition-colors text-center">
                <div className="bg-gray-50 dark:bg-gray-800 p-3 rounded-md">
                  <svg className="w-6 h-6 text-gray-600 dark:text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                  </svg>
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-900 dark:text-white">Action items</div>
                  <div className="text-sm text-gray-600 dark:text-gray-300">from notes</div>
                </div>
              </button>
            </div>
          </div>

          {/* Messages */}
          <div className="px-6 space-y-4">
            {messages.map((message) => (
              <div key={message.id} className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
                <div className={`rounded-lg px-4 py-2 max-w-[80%] ${
                  message.role === 'user' 
                    ? 'bg-blue-600 dark:bg-blue-800' 
                    : 'bg-gray-100 dark:bg-gray-700'
                  }`}>
                  {message.role === 'user'
                    ? <div className="text-sm text-white max-w-none">{message.content}</div> 
                    : <div className="text-sm prose dark:prose-invert max-w-none">
                      <ReactMarkdown>{message.content}</ReactMarkdown>
                    </div>
                  }
                </div>
              </div>
            ))}
            {streamingContent && (
              <div className="flex justify-start">
                <div className={`rounded-lg px-4 py-2 max-w-[80%] bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white`}>
                  <div className="text-sm prose dark:prose-invert max-w-none">
                    <ReactMarkdown>
                      {streamingContent}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            )}
            {isLoading && !streamingContent && (
              <div className="flex justify-start">
                <div className="bg-gray-100 dark:bg-gray-700 rounded-lg px-4 py-2">
                  <div className="flex space-x-2">
                    <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
                    <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
                    <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
                  </div>
                </div>
              </div>
            )}
            {error && (
              <div className="flex justify-center my-4">
                <div className="bg-red-100 dark:bg-red-900 border border-red-400 dark:border-red-700 text-red-700 dark:text-red-200 px-4 py-2 rounded-lg">
                  {error}
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>
        
        {/* Input area - Fixed to bottom */}
        <div className="flex-none bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 px-5 pt-4">
          <form onSubmit={handleSendMessage} className="relative flex items-center gap-3">
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Enter a prompt here"
              className="block flex-1 px-4 py-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-sm font-medium"
            />
            <button 
              type="submit"
              disabled={isLoading}
              className="inline-flex items-center px-4 py-3 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"/>
              </svg>
            </button>
          </form>
          <div className="text-xs text-gray-500 dark:text-gray-400 my-2 text-center">
            {isLoading ? 'AI is thinking...' : 'AI can make mistakes, so double-check it.'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
