import { mockContacts } from './mockContactService';
import { Note } from '../types';

const generateInitialNotes = (): Note[] => [
  // Finance Notes
  {
    id: 1,
    contactId: 1,
    title: 'Commission Structure Review',
    content: `Discussed updated commission structure with ${mockContacts[0].displayName}. Need to prepare new agreement for Q1 2024.`,
    type: 'Finance',
    status: 'Active',
    createdAt: '2024-01-15T10:30:00Z',
    tags: ['commission', 'agreement'],
    attachments: [
      {
        id: 1,
        name: 'commission-structure-2024.pdf',
        url: '/files/commission-structure-2024.pdf',
        type: 'application/pdf'
      }
    ],
    visibility: 'team'
  },
  {
    id: 2,
    contactId: 2,
    title: 'Annual Budget Planning',
    content: 'Reviewed marketing budget allocation for upcoming fiscal year. Increased digital marketing budget by 15%.',
    type: 'Finance',
    status: 'Active',
    createdAt: '2024-01-16T09:00:00Z',
    tags: ['budget', 'planning', 'marketing'],
    attachments: [
      {
        id: 2,
        name: 'budget-2024.xlsx',
        url: '/files/budget-2024.xlsx',
        type: 'application/xlsx'
      }
    ],
    visibility: 'organization'
  },
  {
    id: 3,
    contactId: 3,
    title: 'Expense Report Review',
    content: 'Quarterly expense report analysis. Travel expenses under budget, technology expenses slightly over.',
    type: 'Finance',
    status: 'Active',
    createdAt: '2024-01-17T11:30:00Z',
    tags: ['expenses', 'quarterly-review'],
    attachments: [],
    visibility: 'me'
  },

  // Legal Notes
  {
    id: 4,
    contactId: 4,
    title: 'Franchise Agreement Review',
    content: 'Legal team reviewed franchise renewal terms. Pending signature from broker.',
    type: 'Legal',
    status: 'Active',
    createdAt: '2024-01-16T14:00:00Z',
    tags: ['franchise', 'agreement', 'renewal'],
    attachments: [],
    visibility: 'team'
  },
  {
    id: 5,
    contactId: 5,
    title: 'Compliance Update Meeting',
    content: 'Reviewed new real estate regulations affecting listing presentations. Updates needed in standard forms.',
    type: 'Legal',
    status: 'Active',
    createdAt: '2024-01-18T13:15:00Z',
    tags: ['compliance', 'regulations'],
    attachments: [
      {
        id: 3,
        name: 'compliance-update-2024.pdf',
        url: '/files/compliance-update-2024.pdf',
        type: 'application/pdf'
      }
    ],
    visibility: 'organization'
  },
  {
    id: 6,
    contactId: 6,
    title: 'Contract Template Update',
    content: 'Updated standard listing agreement template with new privacy clauses.',
    type: 'Legal',
    status: 'Active',
    createdAt: '2024-01-19T15:45:00Z',
    tags: ['contracts', 'templates', 'privacy'],
    attachments: [],
    visibility: 'team'
  },

  // Sales Notes
  {
    id: 7,
    contactId: 7,
    title: 'Sales Performance Review',
    content: 'Monthly review of team performance metrics. Above target for luxury property segment.',
    type: 'Sales',
    status: 'Active',
    createdAt: '2024-01-18T11:30:00Z',
    tags: ['performance', 'metrics', 'luxury'],
    attachments: [],
    visibility: 'me'
  },
  {
    id: 8,
    contactId: 8,
    title: 'Lead Generation Strategy',
    content: 'Implemented new social media lead generation campaign. Initial results showing 25% increase in qualified leads.',
    type: 'Sales',
    status: 'Active',
    createdAt: '2024-01-20T10:00:00Z',
    tags: ['leads', 'social-media', 'strategy'],
    attachments: [],
    visibility: 'team'
  },
  {
    id: 9,
    contactId: 9,
    title: 'Sales Pipeline Update',
    content: 'Q1 pipeline review. 15 potential listings identified, 8 in final negotiation stage.',
    type: 'Sales',
    status: 'Active',
    createdAt: '2024-01-21T14:30:00Z',
    tags: ['pipeline', 'listings', 'negotiation'],
    attachments: [],
    visibility: 'team'
  },

  // Marketing Notes
  {
    id: 10,
    contactId: 10,
    title: 'Q1 Marketing Campaign Planning',
    content: 'Discussed digital marketing strategy for luxury listings. Budget approved.',
    type: 'Marketing',
    status: 'Active',
    createdAt: '2024-01-17T09:15:00Z',
    tags: ['marketing', 'digital', 'luxury'],
    attachments: [
      {
        id: 4,
        name: 'marketing-plan-q1.xlsx',
        url: '/files/marketing-plan-q1.xlsx',
        type: 'application/xlsx'
      }
    ],
    visibility: 'organization'
  },
  {
    id: 11,
    contactId: 11,
    title: 'Social Media Content Calendar',
    content: 'Developed content calendar for Q1. Focus on video content and virtual tours.',
    type: 'Marketing',
    status: 'Active',
    createdAt: '2024-01-22T11:00:00Z',
    tags: ['social-media', 'content', 'video'],
    attachments: [],
    visibility: 'team'
  },
  {
    id: 12,
    contactId: 12,
    title: 'Brand Guidelines Update',
    content: 'Updated brand guidelines with new logo usage rules and color palette.',
    type: 'Marketing',
    status: 'Active',
    createdAt: '2024-01-23T13:45:00Z',
    tags: ['brand', 'guidelines', 'design'],
    attachments: [
      {
        id: 5,
        name: 'brand-guidelines-2024.pdf',
        url: '/files/brand-guidelines-2024.pdf',
        type: 'application/pdf'
      }
    ],
    visibility: 'organization'
  },

  // Support Notes
  {
    id: 13,
    contactId: 13,
    title: 'Technical Support Ticket',
    content: 'Resolved MLS integration issues. Updated API credentials provided.',
    type: 'Support',
    status: 'Active',
    createdAt: '2024-01-19T13:45:00Z',
    tags: ['technical', 'MLS', 'integration'],
    attachments: [
      {
        id: 6,
        name: 'integration-guide.pdf',
        url: '/files/integration-guide.pdf',
        type: 'application/pdf'
      }
    ],
    visibility: 'team'
  },
  {
    id: 14,
    contactId: 14,
    title: 'CRM Training Session',
    content: 'Conducted training for new team members on CRM features and best practices.',
    type: 'Support',
    status: 'Active',
    createdAt: '2024-01-24T10:30:00Z',
    tags: ['training', 'CRM', 'onboarding'],
    attachments: [],
    visibility: 'team'
  },
  {
    id: 15,
    contactId: 15,
    title: 'System Maintenance Notice',
    content: 'Scheduled maintenance for CRM system upgrade. New features include improved search functionality.',
    type: 'Support',
    status: 'Active',
    createdAt: '2024-01-25T09:00:00Z',
    tags: ['maintenance', 'upgrade', 'system'],
    attachments: [
      {
        id: 7,
        name: 'maintenance-schedule.pdf',
        url: '/files/maintenance-schedule.pdf',
        type: 'application/pdf'
      }
    ],
    visibility: 'organization'
  }
];

let mockNotes = generateInitialNotes();

interface MockNoteService {
  getNotes: () => Promise<Note[]>;
  getNoteById: (id: string | number) => Promise<Note | undefined>;
  getContactNotes: (contactId: number) => Promise<Note[]>;
  updateNote: (noteId: number, updates: Partial<Note>) => Promise<Note>;
  deleteNote: (noteId: number) => Promise<Note>;
  addNote: (note: Partial<Note>) => Promise<Note>;
}

export const mockNoteService: MockNoteService = {
  getNotes: (): Promise<Note[]> =>
    Promise.resolve(mockNotes),

  getNoteById: (id: string | number): Promise<Note | undefined> =>
    Promise.resolve(mockNotes.find(note => note.id === parseInt(String(id)))),

  getContactNotes: async (contactId: number): Promise<Note[]> => {
    await new Promise(resolve => setTimeout(resolve, 500));
    return mockNotes.filter(note => note.contactId === parseInt(String(contactId)));
  },

  updateNote: (noteId: number, updates: Partial<Note>): Promise<Note> => {
    return new Promise((resolve, reject) => {
      const noteIndex = mockNotes.findIndex(note => note.id === noteId);
      if (noteIndex !== -1) {
        mockNotes[noteIndex] = { ...mockNotes[noteIndex], ...updates };
        resolve(mockNotes[noteIndex]);
      } else {
        reject(new Error('Note not found'));
      }
    });
  },

  deleteNote: (noteId: number): Promise<Note> => {
    return new Promise((resolve, reject) => {
      const noteIndex = mockNotes.findIndex(note => note.id === noteId);
      if (noteIndex !== -1) {
        mockNotes[noteIndex] = { ...mockNotes[noteIndex], status: 'Hidden' };
        resolve(mockNotes[noteIndex]);
      } else {
        reject(new Error('Note not found'));
      }
    });
  },

  addNote: (note: Partial<Note>): Promise<Note> => {
    return new Promise((resolve) => {
      const newNote: Note = {
        id: Math.max(...mockNotes.map(n => n.id)) + 1,
        createdAt: new Date().toISOString(),
        status: 'Active',
        contactId: 0,
        title: '',
        content: '',
        type: 'Finance',
        tags: [],
        attachments: [],
        visibility: 'me',
        ...note
      };
      mockNotes.push(newNote);
      resolve(newNote);
    });
  }
};
