import React, { FC, useState, useMemo } from 'react';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { Pipeline } from '../../types';
import PipelineDetails from './PipelineDetails';

type SortDirection = 'asc' | 'desc';

interface Column {
  key: keyof Pipeline;
  label: string;
}

interface PipelineTableProps {
  pipelines: Pipeline[];
  keywords: string[];
}

const highlightText = (text: string, keywords: string[]): React.ReactNode => {
  if (!text || keywords.length === 0) return text;

  const regex = new RegExp(`(${keywords.map(k => k.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|')})`, 'gi');
  const parts = text.split(regex);

  return parts.map((part, i) =>
    keywords.includes(part.toLowerCase()) ? (
      <span key={i} className="bg-yellow-200 dark:bg-yellow-600">{part}</span>
    ) : (
      part
    )
  );
};

const formatDate = (dateString: string | null) => {
  return dateString ? new Date(dateString).toLocaleDateString() : '-';
};

const columns: Column[] = [
  { key: 'CompanyName', label: 'Company' },
  { key: 'Status', label: 'Status' },
  { key: 'Owners', label: 'Owners' },
  { key: 'Probability', label: 'Probability' },
  { key: 'NextOutreach', label: 'Next Outreach' },
  { key: 'LastOutreach', label: 'Last Outreach' },
  { key: 'TargetCloseDate', label: 'Target Close' }
];

const PipelineTable: FC<PipelineTableProps> = ({ pipelines, keywords }) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof Pipeline;
    direction: SortDirection;
  }>({
    key: 'CompanyName',
    direction: 'asc'
  });
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());

  const toggleRow = (id: number) => {
    setExpandedRows(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const sortedPipelines = useMemo(() => {
    const sorted = [...pipelines];
    sorted.sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue === null) return sortConfig.direction === 'asc' ? 1 : -1;
      if (bValue === null) return sortConfig.direction === 'asc' ? -1 : 1;

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortConfig.direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return sortConfig.direction === 'asc'
        ? (aValue as number) - (bValue as number)
        : (bValue as number) - (aValue as number);
    });
    return sorted;
  }, [pipelines, sortConfig]);

  const handleSort = (key: keyof Pipeline) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full divide-y divide-gray-200 dark:divide-gray-800">
        <thead className={THEME_CLASSES.background}>
          <tr>
            {columns.map(column => (
              <th
                key={column.key}
                onClick={() => handleSort(column.key)}
                className={`px-6 py-2 text-left text-xs font-medium uppercase tracking-wider cursor-pointer ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.text} ${
                  column.key === 'CompanyName' ? 'w-1/4' :
                  column.key === 'Status' ? 'w-1/6' :
                  column.key === 'Owners' ? 'hidden md:table-cell w-1/6' :
                  column.key === 'Probability' ? 'w-1/6' :
                  column.key === 'NextOutreach' ? 'hidden sm:table-cell w-1/6' :
                  'hidden lg:table-cell w-1/12'
                }`}
              >
                <div className="flex items-center space-x-1">
                  <span>{column.label}</span>
                  <span className="inline-block w-4">
                    {sortConfig.key === column.key && (
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        {sortConfig.direction === 'asc' ? (
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                        ) : (
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        )}
                      </svg>
                    )}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={`divide-y divide-gray-200 dark:divide-gray-800 ${THEME_CLASSES.background}`}>
          {sortedPipelines.map((pipeline) => (
            <React.Fragment key={pipeline.ID}>
              <tr
                onClick={() => toggleRow(pipeline.ID)}
                className={`${THEME_CLASSES.hover.background} cursor-pointer`}
              >
                <td className={`px-6 py-2.5 whitespace-nowrap text-xs ${THEME_CLASSES.text.primary}`}>
                  {highlightText(pipeline.CompanyName || '-', keywords)}
                </td>
                <td className={`px-6 py-2.5 whitespace-nowrap text-xs ${THEME_CLASSES.text.secondary}`}>
                  {highlightText(pipeline.Status || '-', keywords)}
                </td>
                <td className={`hidden md:table-cell px-6 py-2.5 whitespace-nowrap text-xs ${THEME_CLASSES.text.secondary}`}>
                  {highlightText(pipeline.Owners || '-', keywords)}
                </td>
                <td className={`px-6 py-2.5 whitespace-nowrap text-xs ${THEME_CLASSES.text.secondary}`}>
                  {(pipeline.Probability * 100).toFixed(0)}%
                </td>
                <td className={`hidden sm:table-cell px-6 py-2.5 whitespace-nowrap text-xs ${THEME_CLASSES.text.secondary}`}>
                  {formatDate(pipeline.NextOutreach)}
                </td>
                <td className={`hidden lg:table-cell px-6 py-2.5 whitespace-nowrap text-xs ${THEME_CLASSES.text.secondary}`}>
                  {formatDate(pipeline.LastOutreach)}
                </td>
                <td className={`hidden lg:table-cell px-6 py-2.5 whitespace-nowrap text-xs ${THEME_CLASSES.text.secondary}`}>
                  {formatDate(pipeline.TargetCloseDate)}
                </td>
              </tr>
              {expandedRows.has(pipeline.ID) && (
                <tr>
                  <td colSpan={columns.length} className="px-6 py-4">
                    <PipelineDetails pipeline={pipeline} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PipelineTable;
