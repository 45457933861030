import { Pipeline } from '../../types';

interface PipelineDetailsProps {
  pipeline: Pipeline;
}

const PipelineDetails: React.FC<PipelineDetailsProps> = ({ pipeline }) => {
  const formatDate = (dateString: string | null) => {
    return dateString ? new Date(dateString).toLocaleDateString() : '-';
  };

  const formatNumber = (value: number | null | undefined) => {
    return value?.toLocaleString() || '-';
  };

  const sections = [
    {
      title: 'Key Dates',
      fields: [
        { label: 'Created Date', value: formatDate(pipeline.CreateDate) },
        { label: 'Last Modified', value: formatDate(pipeline.ModifiedDate) },
        { label: 'Last Outreach', value: formatDate(pipeline.LastOutreach) },
        { label: 'Target Close', value: formatDate(pipeline.TargetCloseDate) },
        { label: 'Disclosure Date', value: formatDate(pipeline.DisclosureDate) }
      ]
    },
    {
      title: 'Location Details',
      fields: [
        { label: 'Markets', value: pipeline.Markets || '-' },
        { label: 'Province', value: pipeline.Province || '-' }
      ]
    },
    {
      title: 'Team Information',
      fields: [
        { label: 'Number of Agents', value: formatNumber(pipeline.NumberOfAgents) }
      ]
    }
  ];

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700 space-y-8">
      {sections.map(section => (
        <div key={section.title}>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
            {section.title}
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {section.fields.map(field => (
              <div
                key={field.label}
                className="hover:bg-gray-50 dark:hover:bg-gray-700 p-4 rounded-lg transition-colors"
              >
                <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  {field.label}
                </div>
                <div className="text-sm font-semibold text-gray-900 dark:text-gray-100">
                  {field.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PipelineDetails;
